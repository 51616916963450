import {
	NotiProvider,
	PrivateRoute,
	ThemeConfig,
	initEnviroment,
} from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import Router from "./router/Router";
import MainLayout from "./layouts/MainLayout";

initEnviroment({
	baseUrl: process.env.REACT_APP_BASE_URL,
	geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

export default hot(function Root() {
	return (
		<PrivateRoute>
			<ThemeConfig>
				<NotiProvider>
					<MainLayout>
						<Router />
					</MainLayout>
				</NotiProvider>
			</ThemeConfig>
		</PrivateRoute>
	);
});
