import { Grid, LoadingButton, DialogContent, useSnackbar } from "@enerbit/base";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormItem } from "../components/dynamic-form";
import { IPostCompany } from "../interfaces";
import { defaultValuesCompany, formCompany } from "../form-defaults";
import { useCompanyStore } from "../store";
import { zodResolver } from "@hookform/resolvers/zod";
import { PostCompanySchema } from "../schemas";
import { StyledDialog, StyledDialogTitle } from "../common";

interface Props {
	open: boolean;
	handleClose: () => void;
}

export const CreateCompany: FC<Props> = ({ open, handleClose }) => {
	const [isLoading, setIsLoading] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { createCompany } = useCompanyStore((state) => state);

	const methods = useForm<IPostCompany>({
		defaultValues: defaultValuesCompany,
		resolver: zodResolver(PostCompanySchema),
		mode: "onChange",
	});

	const {
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors },
	} = methods;

	const onSubmit = async (data: IPostCompany) => {
		try {
			setIsLoading(true);
			await createCompany(data);
			enqueueSnackbar("Empresa creada con éxito.", { variant: "success" });
			reset();
		} catch (error) {
			enqueueSnackbar((error as Error).message, { variant: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<StyledDialog open={open} aria-labelledby="form-dialog-title">
			<StyledDialogTitle id="form-dialog-title" onClose={handleClose}>
				Crear Empresa
			</StyledDialogTitle>
			<DialogContent>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={3}>
							{formCompany.map((formField, index) => {
								return <FormItem {...formField} key={index} />;
							})}
							<Grid item xs={12}>
								<LoadingButton
									loading={isLoading}
									id="create-company"
									type="submit"
									variant="contained"
									color="secondary"
								>
									Crear empresa
								</LoadingButton>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</DialogContent>
		</StyledDialog>
	);
};
