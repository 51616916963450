import { create } from "zustand";
import { ICompanyItem, ICompanyState, IRespCompany } from "../interfaces";
import { api } from "@enerbit/base";

export const useCompanyStore = create<ICompanyState>()((set) => ({
	mainSearch: "",
	loading: false,
	pagination: {
		contracts: {
			page: 0,
			size: 6,
			total: 1,
		},
		documentation: {
			page: 0,
			size: 6,
			total: 1,
		},
	},
	listFiles: {
		contracts: [],
		documentation: [],
	},
	setMainSearch: (search) => set(() => ({ mainSearch: search })),
	createCompany: async (companyData) => {
		try {
			await api.post("/ops-users/companies/", companyData);
		} catch (error) {
			throw new Error("Error al crear empresa.");
		}
	},
	filterCompany: async (filter) => {
		try {
			set({ loading: true });
			const { data } = await api.get<IRespCompany>(
				`/ops-users/companies/?legal_id_code=${filter}`,
			);

			if (data.items.length === 0) {
				throw new Error(
					"No se encontraron empresas con el filtro proporcionado.",
				);
			}
			set({ company: data.items[0], loading: false });
		} catch (error) {
			set({ loading: false });
			throw error;
		}
	},
	getCompany: async (id) => {
		try {
			set({ loading: true });
			const { data } = await api.get<ICompanyItem>(
				`/ops-users/companies/${id}/`,
			);
			set({ company: data, loading: false });
		} catch (error) {
			set({ company: undefined, loading: false });
		}
	},
	editCompany: async (id, companyData) => {
		try {
			await api.patch(`/ops-users/companies/${id}`, companyData);
		} catch (error) {
			throw new Error("Error al editar empresa.");
		}
	},
	setCompany: (company) => set(() => ({ company })),
	clearCompany: () => set(() => ({ company: undefined })),
}));
