import { Grid } from "@enerbit/base";
import { UserItem } from "./UserItem";
import { useUserStore } from "../../store";
import { UserItemSkeleton } from "../skeleton";
import { PaginationUser } from ".";

export const UserList = () => {
	const { listUsers, loadingUsers } = useUserStore((state) => state);
	return (
		<Grid
			container
			item
			spacing={3}
			style={{ display: "flex", alignItems: "stretch" }}
		>
			{loadingUsers ? (
				<>
					{new Array(6).fill(0).map((_, index) => {
						return <UserItemSkeleton key={index} />;
					})}
				</>
			) : (
				<>
					{listUsers.map((item, index) => {
						return <UserItem {...item} key={index} />;
					})}
				</>
			)}
			<PaginationUser />
		</Grid>
	);
};
