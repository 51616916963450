import {
	FormControlLabel,
	Grid,
	InputLabel,
	Switch,
	enerbitColors,
	useSnackbar,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useUserStore } from "../../store";
import { useTeamStore } from "../../store/team";
import { EditUserDialog } from "./EditUserDialog";

export const ConfirmSwitch = () => {
	const { id } = useParams();

	const { control, setValue, watch } = useFormContext();
	const isActive = useWatch({ control, name: "is_active" });
	const [openDialog, setOpenDialog] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { disableUser, roles } = useUserStore((state) => state);
	const { getTeams, listTeams } = useTeamStore();

	useEffect(() => {
		if (!isActive) {
			setOpenDialog(false);
		}
	}, [isActive]);

	const handleSwitchClick = () => {
		if (isActive) {
			setOpenDialog(true);

			const rolId = watch("rol_id");

			const currentRoleCode =
				roles.find(({ value }) => value === rolId)?.code ?? "";

			if (
				id &&
				(currentRoleCode === "OPR" ||
					currentRoleCode === "operacion_energia_digital")
			) {
				getTeams(id);
			}
		}
	};

	const handleDialogClose = async (accept: boolean) => {
		if (accept && isActive) {
			if (listTeams.length === 0) {
				try {
					await disableUser(id ?? "");
					setValue("is_active", false);
					enqueueSnackbar("Usuario actualizado con éxito.", {
						variant: "success",
					});
				} catch (error) {
					enqueueSnackbar((error as Error).message, { variant: "error" });
				}
			} else {
				enqueueSnackbar(
					"Para poder desactivar este operador debe eliminarlo o remplazarlo de las cuadrillas activas existentes.",
					{ variant: "warning" },
				);
			}
		}
		setOpenDialog(false);
	};

	return (
		<Grid item>
			<InputLabel
				sx={{
					display: "flex",
					alignItems: "end",
					color: enerbitColors.primary.main,
					fontWeight: "bold",
				}}
			>
				¿Desea mantener el usuario activo?
			</InputLabel>
			<FormControlLabel
				control={
					<Switch
						color="secondary"
						checked={isActive}
						onClick={handleSwitchClick}
						disabled={!isActive} // Disable the switch if it's not active
					/>
				}
				label={isActive ? "Si" : "No"}
			/>
			<EditUserDialog
				open={openDialog}
				onClose={() => handleDialogClose(false)}
				onConfirm={() => handleDialogClose(true)}
			/>
		</Grid>
	);
};
