import { Company } from "../components/company";
import { User } from "../components/user";
import "./UsersCompanies.css";
import { Grid } from "@enerbit/base";

export const UsersCompanies = () => {
	return (
		<Grid container spacing={3}>
			<Company />
			<User />
		</Grid>
	);
};
