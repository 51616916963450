import { ComponentType } from "react";
import { IControlType, IDynamicForm } from "../../interfaces";
import { ArrayForm } from "./items/ArrayForm";
import { AutocompleteForm } from "./items/AutocompleteForm";
import { DateForm } from "./items/DateForm";
import { ImageForm } from "./items/ImageForm";
import { SelectForm } from "./items/SelectForm";
import { SwitchForm } from "./items/SwitchForm";
import { TextForm } from "./items/TextForm";
import { TitleForm } from "./items/TitleForm";
import { ProfileImageForm } from "./items/ProfileImageForm";
import { AccordionForm } from "./items/AccordionForm";
import { FileForm } from "./items/FileForm";
import { CheckboxGroupForm } from "./items/CheckboxGroupForm";

export const ComponentMap: Record<IControlType, ComponentType<IDynamicForm>> = {
	text: TextForm,
	email: TextForm,
	number: TextForm,
	"text-area": TextForm,
	password: TextForm,
	select: SelectForm,
	autocomplete: AutocompleteForm,
	image: ImageForm,
	file: FileForm,
	date: DateForm,
	switch: SwitchForm,
	array: ArrayForm,
	title: TitleForm,
	profile: ProfileImageForm,
	accordion: AccordionForm,
	"checkbox-group": CheckboxGroupForm,
};
