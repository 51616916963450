export const getRandomColor = (): string => {
	// Genera un número aleatorio entre 0 y 255 y lo convierte a hexadecimal
	const randomHex = (): string =>
		Math.floor(Math.random() * 256)
			.toString(16)
			.padStart(2, "0");

	// Genera los componentes rojo, verde y azul
	const red = randomHex();
	const green = randomHex();
	const blue = randomHex();

	// Concatena los componentes para formar el color en formato hexadecimal
	return `#${red}${green}${blue}`;
};

export const stringToColor = (str: string): string => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = [0, 1, 2]
		.map((value) => {
			const component = (hash >> (value * 8)) & 0xff;
			return component.toString(16).padStart(2, "0");
		})
		.join("");

	// Verifica si el color es demasiado claro y ajusta los componentes
	if (isColorTooLight(color)) {
		color = adjustColor(color);
	}

	return `#${color}`;
};

const isColorTooLight = (color: string): boolean => {
	const rgb = parseInt(color, 16); // Convierte el color a un número entero
	const r = (rgb >> 16) & 0xff;
	const g = (rgb >> 8) & 0xff;
	const b = rgb & 0xff;
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
	return brightness > 200; // Límite para colores claros
};

const adjustColor = (color: string): string => {
	const rgb = parseInt(color, 16);
	let r = (rgb >> 16) & 0xff;
	let g = (rgb >> 8) & 0xff;
	let b = rgb & 0xff;

	// Reduce la claridad de cada componente
	r = Math.max(r - 50, 0);
	g = Math.max(g - 50, 0);
	b = Math.max(b - 50, 0);

	return [r, g, b].map((c) => c.toString(16).padStart(2, "0")).join("");
};
