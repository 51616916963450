import {
	Accordion as MuiAccordion,
	AccordionSummary as MuiAccordionSummary,
	AccordionDetails as MuiAccordionDetails,
	styled,
} from "@enerbit/base";

export const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
	borderRadius: "16px !important",
	// Aquí puedes añadir más estilos personalizados
}));

export const StyledAccordionSummary = styled(MuiAccordionSummary)(
	({ theme }) => ({
		minHeight: 56,
		borderRadius: "16px !important",
		"&.Mui-expanded": {
			minHeight: 56,
			borderRadius: "16px 16px 0 0 !important",
		},
		flexDirection: "row-reverse",
		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(2),
			"&.Mui-expanded": {
				margin: "20px !important",
			},
		},
	}),
);

export const StyledAccordionDetails = styled(MuiAccordionDetails)(
	({ theme }) => ({
		padding: theme.spacing(2),
		// Aquí puedes añadir más estilos personalizados
	}),
);
