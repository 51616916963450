import { api } from "@enerbit/base";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { IRespWorkGroups, ITeamState } from "../interfaces";

export const useTeamStore = create<ITeamState>()(
	devtools(
		(set) => ({
			loadingTeams: false,
			listTeams: [],
			getTeams: async (id) => {
				try {
					set({ loadingTeams: true, listTeams: [] });
					const { data } = await api.get<IRespWorkGroups>(
						`/work-groups/work-groups?page=0&size=50&operator_id=${id}&is_active=true`,
					);
					set({
						listTeams: data.items,
					});
				} catch (error) {
					throw new Error("Error al consultar cuadrillas.");
				} finally {
					set({ loadingTeams: false });
				}
			},
		}),
		{ anonymousActionType: "team" },
	),
);
