import {
	Grid,
	Box,
	Typography,
	Stack,
	Tooltip,
	NoteAltOutlinedIcon,
	RemoveRedEyeIcon,
} from "@enerbit/base";
import { StyledIconButton } from "../../common";
import { FC } from "react";
import { IContractItem } from "../../interfaces";
import moment from "moment";
import { Link } from "react-router-dom";
import { useJWTStore } from "../../store";

export const FileCompanyItem: FC<IContractItem> = ({
	code,
	started_at,
	id,
}) => {
	const { decodedToken } = useJWTStore();

	return (
		<Grid item xs={12} lg={4} md={6}>
			<Box
				sx={{
					p: 3,
					border: "1px solid #E4E7EC",
					borderRadius: "12px",
					color: "#667085",
					display: "flex",
					height: "100%", // Asegura que tome toda la altura disponible
					width: "100%",
				}}
			>
				<Box width={"70%"} display="flex">
					<Grid container columnSpacing={2}>
						<Grid item>
							<Box className="pdf">PDF</Box>
						</Grid>
						<Grid item>
							<Typography
								className="item-box-certification-edit"
								sx={{ fontSize: "16px", fontWeight: "bold" }}
							>
								Contrato {code}
							</Typography>
							<Typography
								className="item-box-certification-edit"
								sx={{ fontSize: "16px" }}
							>
								Fecha: {moment(started_at).format("YYYY/MM/DD")}
							</Typography>
						</Grid>
						<Grid item></Grid>
					</Grid>
				</Box>
				<Stack
					direction="row"
					spacing={1}
					sx={{
						width: "30%",
						justifyContent: "end",
						alignItems: "center",
					}}
				>
					{!(decodedToken?.role === "contratista") ? (
						<Tooltip title="Ver y editar contrato">
							<Link to={`/users-companies/edit-contract/${id}`}>
								<StyledIconButton color="primary">
									<NoteAltOutlinedIcon />
								</StyledIconButton>
							</Link>
						</Tooltip>
					) : (
						<Tooltip title="Ver contrato">
							<Link to={`/users-companies/contract/${id}`}>
								<StyledIconButton color="primary">
									<RemoveRedEyeIcon />
								</StyledIconButton>
							</Link>
						</Tooltip>
					)}
				</Stack>
			</Box>
		</Grid>
	);
};
