import {
	Avatar,
	CircularProgress,
	EditIcon,
	Grid,
	enerbitColors,
} from "@enerbit/base";
import { type ChangeEvent, type FC, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import bombhappy from "/public/bombhappy.png";
import { StyledBadge, StyledIconButton } from "../../../common";
import type { IDynamicForm } from "../../../interfaces";
import { loadFile, uploadFile } from "../../../services";

export const ProfileImageForm: FC<IDynamicForm> = ({ name, sx }) => {
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState<number>(0);

	const { setValue, watch } = useFormContext();

	const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			try {
				setIsLoading(true);
				setProgress(0);
				const uploadedImageUrl = await uploadFile(
					file,
					(event: ProgressEvent) => {
						const percentCompleted = Math.round(
							(event.loaded * 100) / event.total,
						);
						setProgress(percentCompleted);
					},
				);

				// Autenticar la URL de la imagen recién subida
				const authenticatedUrl = await loadFile(uploadedImageUrl);

				console.log(authenticatedUrl);

				setValue(`${name}.path_photography`, authenticatedUrl);
				setIsLoading(false);
			} catch (error) {
				console.error("Error al subir o autenticar la imagen:", error);
			}
		}
	};

	const handleEditClick = () => {
		inputFileRef.current?.click();
	};

	return (
		<Grid item xs={sx?.xs} sx={{ display: "flex", justifyContent: "center" }}>
			<div style={{ position: "relative", display: "inline-flex" }}>
				<StyledBadge
					overlap="circular"
					color="primary"
					badgeContent={
						<>
							<input
								disabled={isLoading}
								ref={inputFileRef}
								accept="image/*"
								style={{ display: "none" }}
								id="profile-image-upload"
								type="file"
								onChange={handleImageChange}
							/>
							<StyledIconButton
								disabled={isLoading}
								color="primary"
								sx={{ borderRadius: "50%", zIndex: 0 }}
								size="small"
								onClick={handleEditClick}
								id={`edit-${name}`}
							>
								<EditIcon color="secondary" />
							</StyledIconButton>
						</>
					}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
				>
					<Avatar
						sx={{
							width: "200px",
							height: "200px",
							objectFit: !watch(`${name}.path_photography`)
								? "contain"
								: undefined,
							border: `4px solid ${enerbitColors.primary.main}`,
						}}
						src={watch(`${name}.path_photography`) ?? bombhappy}
					/>
				</StyledBadge>
				{isLoading && (
					<CircularProgress
						variant="determinate"
						value={progress}
						size={210} // Ajusta el tamaño para rodear el Avatar
						thickness={1} // Ajusta el grosor de la barra de progreso
						sx={{
							color: enerbitColors.primary.main, // Ajusta el color según tus necesidades
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: 1,
							marginTop: "-5px", // Ajusta para alinear con el borde del Avatar
							marginLeft: "-5px", // Ajusta para alinear con el borde del Avatar
							borderRadius: "50%", // Hace que el progreso tenga bordes redondeados
						}}
					/>
				)}
			</div>
		</Grid>
	);
};
