import { FC } from "react";
import { IDynamicForm } from "../../interfaces";
import { FormItem } from "../dynamic-form";

interface Props {
	items: IDynamicForm[];
}

export const RenderFormItems: FC<Props> = ({ items }) => {
	return (
		<>
			{items.map((formField, index) => (
				<FormItem {...formField} key={index} />
			))}
		</>
	);
};
