import { FC } from "react";
import { useFormContext, Controller, get } from "react-hook-form";
import {
	Grid,
	FormControlLabel,
	Checkbox,
	InputLabel,
	FormHelperText,
} from "@enerbit/base";
import { IDynamicForm, IItemsJson } from "../../../interfaces";

export const CheckboxGroupForm: FC<IDynamicForm> = ({
	name,
	label,
	items,
	sx,
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<Grid container item spacing={3} xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<Grid item xs={12}>
				<InputLabel>
					{label}
					{!!get(errors, name) && (
						<FormHelperText error>
							{" "}
							({get(errors, name).message})
						</FormHelperText>
					)}
				</InputLabel>
			</Grid>
			{items?.map((item: IItemsJson, index: number) => (
				<Grid item xs={4} md={2} key={index}>
					{" "}
					{/* Ajusta el marginBottom según necesites */}
					<FormControlLabel
						control={
							<Controller
								name={name!}
								control={control}
								render={({ field }) => (
									<Checkbox
										{...field}
										id={`${name}-${index}`}
										checked={
											field.value ? field.value.includes(item.value) : false
										}
										onChange={(e) => {
											const newValue = field.value ? [...field.value] : [];
											if (e.target.checked) {
												newValue.push(item.value);
											} else {
												const index = newValue.indexOf(item.value);
												if (index >= 0) {
													newValue.splice(index, 1);
												}
											}
											field.onChange(newValue);
										}}
									/>
								)}
							/>
						}
						label={item.text}
					/>
				</Grid>
			))}
		</Grid>
	);
};
