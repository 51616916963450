import { z } from "zod";

const isDateAfter = (dateString: string | null, afterDate: Date): boolean =>
	dateString !== null && new Date(dateString) >= afterDate;

const subtractMonths = (date: Date, months: number): Date => {
	const newDate = new Date(date);
	newDate.setMonth(newDate.getMonth() - months);
	return newDate;
};

const createExpirationSchema = () =>
	z
		.string()
		.refine((val) => !val || isDate(val), { message: "Fecha inválida." });

const dateFromYearsAgo = (years: number): Date =>
	new Date(new Date().setFullYear(new Date().getFullYear() - years));

const isDate = (dateString: string | null): boolean =>
	dateString !== null && !Number.isNaN(new Date(dateString).getTime());

const isDateBeforeExpirationDays = (
	dateString: string | null,
	daysBefore: number,
): boolean => {
	if (dateString === null) return false;
	const currentDate = new Date();
	const expirationDate = new Date(dateString);
	const limitDate = new Date(expirationDate);
	limitDate.setDate(limitDate.getDate() - daysBefore);
	return currentDate < limitDate;
};

export const isDateBeforeExpirationMonths = (
	dateString: string | null,
	monthsBefore: number,
): boolean => {
	if (dateString === null) return false;
	const currentDate = new Date();
	const expirationDate = new Date(dateString);
	const limitDate = new Date(expirationDate);
	limitDate.setMonth(limitDate.getMonth() - monthsBefore);
	return currentDate < limitDate;
};

const createExpirationSchemaDays = () =>
	z
		.string()
		.nullable()
		.transform((val) => (isDate(val) ? val : ""));

const DocumentSchema = z.object({
	path_certification: z.optional(z.string().nullable()),
	date_issue: createExpirationSchema(),
});

const LegalDocumentSchema = z.object({
	path_certification: z.optional(z.string().nullable()),
	date_issue: z.string(),
});

const AffiliationSchema = z.object({
	path_certification: z
		.string()
		.nullable()
		.transform((val) => val ?? "")
		.refine((val) => val.length > 0, "Este campo es obligatorio."),
	entity: z
		.string()
		.nullable()
		.transform((val) => val ?? "")
		.refine((val) => val.length > 0, "Este campo es obligatorio."),
	expiration_certification: z
		.string()
		.nullable()
		.transform((val) => (isDate(val) ? val : null))
		.refine((val) => val !== null && val.length > 0, {
			message: "Este campo es obligatorio.",
		}),
});

const SocialSecurityPaymentFormSchema = z.object({
	affiliation_arl: z.array(AffiliationSchema).nullable().default(null),
	affiliation_eps: z.array(AffiliationSchema).nullable().default(null),
	affiliation_pension: z.array(AffiliationSchema).nullable().default(null),
});

const ConfinedAreasSchema = z.object({
	path_certification: z.string(),
	date_issue: createExpirationSchema(),
});

const ImageProfileSchema = z.object({
	path_photography: z.string(),
});

const ProfessionalRegistrationSchema = z.object({
	path_certification: z.optional(z.string().nullable()),
	date_issue: z.string(),
	registration_number: z.string().nullable(),
	entity: z.string().nullable(),
});

const EmergencyContactSchema = z.object({
	full_name: z.string().min(1, "Este campo es obligatorio."),
	phone: z.string().min(1, "Este campo es obligatorio."),
});

export const FormDataOperatorSchema = z.object({
	image_profile: ImageProfileSchema.nullable(),
	social_security_payment_form: SocialSecurityPaymentFormSchema.nullable(),
	drive_license: z
		.array(
			z.object({
				path_certification: z.optional(z.string().nullable()),
				expiration_certification: createExpirationSchemaDays(),
			}),
		)
		.nullable(),
	safe_work_height: z.array(
		z.object({
			path_certification: z.optional(z.string().nullable()),
			expiration_certification: createExpirationSchemaDays(),
		}),
	),
	introduction_road_safety: DocumentSchema,
	load_lifting: z.array(
		z.object({
			path_certification: z.optional(z.string().nullable()),
			expiration_certification: createExpirationSchemaDays(),
		}),
	),
	confined_areas: ConfinedAreasSchema.nullable(),
	electrical_hazard_training: z.array(
		z.object({
			path_certification: z.optional(z.string().nullable()),
			expiration_certification: createExpirationSchemaDays(),
		}),
	),
	first_aid_training: z.object({
		path_certification: z.optional(z.string()),
		date_issue: createExpirationSchema(),
	}),
	professional_registration: ProfessionalRegistrationSchema,
	legal_id_document: LegalDocumentSchema,
	blood_type: z.string().nullable(),
	health_record: z.string().nullable(),
	residence: z.string().nullable(),
	emergency_contact: EmergencyContactSchema.nullable(),
	coordinator: z
		.object({
			safe_work_height: z
				.object({
					path_certification: z.optional(z.string().nullable()),
					expiration_certification: createExpirationSchemaDays(),
				})
				.nullable(),
		})
		.nullable(),
	measurement_type_trained: z.array(z.string()).nullable(),
	company_vehicle_driving_authorization: z
		.object({
			path_certification: z.optional(z.string().nullable()),
			date_issue: createExpirationSchema(),
		})
		.nullable(),
	voltage_works_permission: z
		.object({
			path_certification: z.optional(z.string().default("")),
			expiration_certification: createExpirationSchemaDays().default(""),
		})
		.nullable(),
});
