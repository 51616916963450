import { z } from "zod";
import { FormDataOperatorSchema } from ".";

export const PostUserSchema = z.object({
	password: z.string().min(1, "Este campo es obligatorio."),
	rol_id: z.string().min(1, "Este campo es obligatorio."),
	company_id: z.string().min(1, "Este campo es obligatorio."),
	full_name: z.string().min(1, "Este campo es obligatorio."),
	legal_id_type: z.string().min(1, "Este campo es obligatorio."),
	legal_id_code: z.string().min(1, "Este campo es obligatorio."),
	phone: z.string().min(1, "Este campo es obligatorio."),
	email: z
		.string()
		.min(1, "Este campo es obligatorio.")
		.email("Correo electrónico invalido."),
	country: z
		.string()
		.optional()
		.refine((val) => val != null && val !== "", "Este campo es obligatorio."),
	state: z
		.string()
		.optional()
		.refine((val) => val != null && val !== "", "Este campo es obligatorio."),
	city: z
		.string()
		.optional()
		.refine((val) => val != null && val !== "", "Este campo es obligatorio."),
	form_data: FormDataOperatorSchema.nullable(),
});
