import { IDynamicForm } from "../interfaces";

export const formPersonalInformation: IDynamicForm[] = [
	{
		label: "Foto de perfil",
		name: "image_profile",
		type: "profile",
		sx: {
			xs: 12,
		},
	},
	{
		name: "residence",
		type: "text",
		label: "Domicilio principal",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Documento de identidad",
		type: "title",
	},
	{
		label: "Fecha de expedición",
		name: "legal_id_document.date_issue",
		type: "date",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		props: {
			disableFuture: true,
		},
	},
	{
		label: "Documento de identidad",
		name: "legal_id_document.path_certification",
		type: "file",
	},
	{
		label: "Información medica",
		type: "title",
	},
	{
		label: "Tipo de sangre",
		name: "blood_type",
		type: "select",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		items: [
			{
				text: "A+",
				value: "A+",
			},
			{
				text: "A-",
				value: "A-",
			},
			{
				text: "B+",
				value: "B+",
			},
			{
				text: "B-",
				value: "B-",
			},
			{
				text: "AB+",
				value: "AB+",
			},
			{
				text: "AB-",
				value: "AB-",
			},
			{
				text: "O+",
				value: "O+",
			},
			{
				text: "O-",
				value: "O-",
			},
		],
	},
	{
		name: "health_record",
		type: "text-area",
		label: "Si presenta patologías, describalas:",
		sx: {
			xs: 12,
		},
	},
	{
		label: "Contacto de emergencia",
		name: "emergency_contact",
		type: "switch",
		sx: {
			xs: 12,
		},
		children: [
			{
				name: "full_name",
				type: "text",
				label: "Nombre completo",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
			},
			{
				name: "phone",
				type: "text",
				label: "Número de teléfono",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
			},
		],
		json: {
			full_name: "",
			phone: "",
		},
	},
];
