import { IDynamicForm } from "../interfaces";

export const formCreateContract: IDynamicForm[] = [
	{
		label: "Código",
		name: "code",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Valor",
		name: "value",
		type: "number",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		props: {
			min: 0,
		},
	},
	{
		label: "Fecha inicial",
		name: "started_at",
		type: "date",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Fecha final",
		name: "ended_at",
		type: "date",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		props: {
			disableDatesBefore: "started_at",
		},
	},
	{
		label: "Descripción",
		name: "description",
		type: "text-area",
		sx: {
			xs: 12,
		},
	},
];
