import { Grid, Skeleton, Box } from "@enerbit/base";

export const InfoCompanySkeleton = () => {
	return (
		<Grid item container spacing={3}>
			<Grid item>
				{/* Skeleton para el nombre de la compañía */}
				<Skeleton variant="text" width={210} height={60} />
			</Grid>
			<Grid item xs={12}>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						gap: 3,
					}}
				>
					{/* Skeletons para los detalles de la compañía */}
					<Box sx={{ flex: 1 }}>
						<Skeleton variant="text" width="50%" height={20} />
						<Skeleton variant="text" width="40%" height={30} />
					</Box>
					<Box sx={{ flex: 1 }}>
						<Skeleton variant="text" width="50%" height={20} />
						<Skeleton variant="text" width="40%" height={30} />
					</Box>
					<Box sx={{ flex: 1 }}>
						<Skeleton variant="text" width="50%" height={20} />
						<Skeleton variant="text" width="40%" height={30} />
					</Box>
					<Box sx={{ flex: 1 }}>
						<Skeleton variant="text" width="50%" height={20} />
						<Skeleton variant="text" width="40%" height={30} />
					</Box>
					<Skeleton variant="rounded" width={40} height={40} />
				</Box>
			</Grid>
		</Grid>
	);
};
