import { Grid, LoadingButton, Typography, useSnackbar } from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { type FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { PersonalInformation } from ".";
import {
	formCertificationOperator,
	formUpdateDocumentOperator,
	formUpdateDocumentationOperator,
	formUpdateUser,
	updateDocumentationVehicleAuthorization,
} from "../../form-builders";
import {
	createDefaultDocument,
	defaultValuesFormData,
} from "../../form-defaults";
import { cleanObject } from "../../helpers/cleanObject";
import type { IUserItem } from "../../interfaces";
import { PatchUserSchema } from "../../schemas";
import { useJWTStore, useUserStore } from "../../store";
import { useTeamStore } from "../../store/team";
import { FormItem } from "../dynamic-form";
import { RenderFormItems } from "../render";
import { ConfirmSwitch } from "./ConfirmSwicth";
import { DynamicLocationForm } from "./DynamicLocationForm";
import { DynamicVehicleForm } from "./DynamicVehicleForm";

interface Props {
	userData: IUserItem;
}

export const EditUserForm: FC<Props> = ({ userData: user }) => {
	const { id } = useParams();

	const [isLoading, setIsLoading] = useState(false);
	const [currentRol, setCurrentRol] = useState<string | null>(null);

	const { enqueueSnackbar } = useSnackbar();

	const methods = useForm<IUserItem>({
		resolver: zodResolver(PatchUserSchema),
		defaultValues: user,
		mode: "onChange",
	});
	const {
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = methods;

	const { roles, updateUser } = useUserStore((state) => state);
	const { getTeams, loadingTeams, listTeams } = useTeamStore();

	const { decodedToken } = useJWTStore();
	const isEBNotContained = decodedToken?.role === "contratista";

	console.log(watch());

	const onSubmit = async (data: IUserItem) => {
		try {
			setIsLoading(true);

			const form_data = data.pii?.form_data
				? cleanObject(data.pii?.form_data)
				: null;

			const piiData = data.pii
				? {
						...data.pii,
						full_name: data.pii.full_name || "",
						form_data,
					}
				: null;

			await updateUser(id ?? "", {
				...data,
				username: data.pii?.email ?? "",
				pii: piiData,
			});
			enqueueSnackbar("Usuario actualizado con éxito.", { variant: "success" });
		} catch (error) {
			console.log(error);
			enqueueSnackbar((error as Error).message, { variant: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	const handleFormData = () => {
		const rolId = watch("rol_id");
		const formData = watch("pii.form_data");
		if (rolId && roles.length > 0) {
			const currentRoleCode =
				roles.find(({ value }) => value === rolId)?.code ?? "";

			setCurrentRol(currentRoleCode);

			if (
				currentRoleCode === "OPR" ||
				currentRoleCode === "operacion_energia_digital"
			) {
				setValue("pii.form_data", formData ? formData : defaultValuesFormData);
			} else {
				setValue("pii.form_data", null);
			}
		} else {
			setValue("pii.form_data", null);
		}
	};

	const isFirstRender = useRef(true);
	const isFirstRender2 = useRef(true);

	useEffect(() => {
		if (!isFirstRender.current) {
			handleFormData();
		}

		isFirstRender.current = false;
	}, [watch("rol_id"), roles]);

	useEffect(() => {
		if (
			!isFirstRender.current &&
			!isFirstRender2.current &&
			watch("pii.form_data") &&
			currentRol
		) {
			if (currentRol === "operacion_energia_digital") {
				setValue(
					"pii.form_data.company_vehicle_driving_authorization",
					watch("pii.form_data.company_vehicle_driving_authorization")
						? watch("pii.form_data.company_vehicle_driving_authorization")
						: createDefaultDocument(),
				);
			} else {
				setValue("pii.form_data.company_vehicle_driving_authorization", null);
			}
		}

		isFirstRender2.current = false;
	}, [currentRol, watch("pii.form_data")]);

	useEffect(() => {
		const previousRol = currentRol;
		const newRol =
			roles.find(({ value }) => value === watch("rol_id"))?.code ?? "";

		const handleRoleChange = async () => {
			if (
				previousRol &&
				(previousRol === "OPR" ||
					previousRol === "operacion_energia_digital") &&
				newRol !== "OPR" &&
				newRol !== "operacion_energia_digital"
			) {
				console.log(`El rol ha cambiado de ${previousRol} a ${newRol}`);
				await getTeams(id ?? "");
				if (listTeams.length > 0) {
					enqueueSnackbar(
						"Para poder cambiar el rol de este usuario debe eliminarlo o reemplazarlo de las cuadrillas activas existentes.",
						{ variant: "warning" },
					);
					// No realizar cambios adicionales en los valores aquí
				}
			}
		};

		handleRoleChange();
	}, [watch("rol_id")]);

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ConfirmSwitch />
					<Grid container spacing={3}>
						<RenderFormItems items={formUpdateUser.slice(0, 6)} />
						<DynamicLocationForm
							nameCountry={"pii.country"}
							nameState={"pii.state"}
							nameCity={"pii.city"}
						/>
						{isEBNotContained ? (
							<>
								<RenderFormItems
									items={formUpdateUser.slice(6, 9).map((item) => {
										if (item.name === "rol_id") {
											return {
												...item,
												props: {
													disabled: true,
													...item.props,
												},
											};
										}
										return item;
									})}
								/>
								<RenderFormItems items={formUpdateUser.slice(10)} />
							</>
						) : (
							<RenderFormItems items={formUpdateUser.slice(6)} />
						)}
						{!!watch("pii.form_data") && (
							<>
								<FormItem
									name="pii.form_data.measurement_type_trained"
									type={"checkbox-group"}
									label="Seleccione el tipo de medida que maneja este operador"
									items={[
										{
											text: "Directa",
											value: "directa",
										},
										{
											text: "Semidirecta",
											value: "semidirecta",
										},
										{
											text: "Indirecta",
											value: "indirecta",
										},
									]}
								/>
								<Grid item xs={12}>
									<Typography
										color="primary"
										sx={{ fontSize: "28px", fontWeight: "bold" }}
									>
										Documentación
									</Typography>
								</Grid>
								<FormItem
									name="pii.form_data.coordinator.safe_work_height"
									type={"switch"}
									label="¿Es coordinador/a de trabajo en alturas?"
									children={[
										{
											label:
												"Certificado de trabajo en alturas ( coordinador )",
											type: "accordion",
											children: formCertificationOperator,
										},
									]}
									sx={{
										xs: 12,
									}}
									json={{ safe_work_height: null }}
								/>
								<FormItem
									name="pii.form_data.confined_areas"
									type={"switch"}
									label="¿Está habilitada/o para trabajo en espacios confinados?"
									children={[
										{
											label: "Trabajo en espacios confinados",
											type: "accordion",
											children: formUpdateDocumentOperator,
										},
									]}
									sx={{
										xs: 12,
									}}
									json={createDefaultDocument()}
								/>
								{currentRol === "OPR" && (
									<DynamicVehicleForm
										name={"pii.form_data"}
										currentRol={currentRol ?? ""}
									/>
								)}
								{currentRol === "operacion_energia_digital" &&
									updateDocumentationVehicleAuthorization.map(
										(formField, index) => {
											return <FormItem {...formField} key={index.toString()} />;
										},
									)}
								{formUpdateDocumentationOperator
									.slice(0, 7)
									.map((formField, index) => {
										return <FormItem {...formField} key={index.toString()} />;
									})}
								<PersonalInformation name={"pii.form_data"} />
								{currentRol === "operacion_energia_digital" && (
									<DynamicVehicleForm
										name={"pii.form_data"}
										currentRol={currentRol ?? ""}
									/>
								)}
								{formUpdateDocumentationOperator
									.slice(7)
									.map((formField, index) => {
										return <FormItem {...formField} key={index.toString()} />;
									})}
							</>
						)}
						<Grid item xs={12}>
							<LoadingButton
								disabled={!watch("is_active") || listTeams.length > 0}
								loading={isLoading || loadingTeams}
								id="create-user"
								type="submit"
								variant="contained"
								color="secondary"
							>
								Editar usuario
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</>
	);
};
