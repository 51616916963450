import { create } from "zustand";
import type {
	IFiltersUser,
	IFiltersUserState,
} from "../interfaces/IFilterUserState";

const initialState: IFiltersUser = {
	selectedCompanies:[],
	selectedRoles: [],
	selectedStatus: [],
	selectedState: [],
	selectedCity: [],
	selectedMeter: [],
	selectedPiiStatus: [],
	selectedCoordinator: [],
	selectedSafeWorkHeightCoordinator: [],
	selectedSafeWorkHeight: [],
	isRoleOpera: false,
};

const updateFilter =
	(
		set: (
			partial:
				| IFiltersUser
				| Partial<IFiltersUser>
				| ((state: IFiltersUser) => IFiltersUser | Partial<IFiltersUser>),
			replace?: boolean | undefined,
		) => void,
	) =>
	(filterName: keyof IFiltersUser, value: string[] | boolean) =>
		set((state) => ({
			...state,
			[filterName]: value,
		}));

export const useFilterUserStore = create<IFiltersUser & IFiltersUserState>(
	(set) => ({
		...initialState,
		setSelectedCompanies: (options) => updateFilter(set)("selectedCompanies", options),
		setSelectedRoles: (options) => updateFilter(set)("selectedRoles", options),
		setSelectedStatus: (options) =>
			updateFilter(set)("selectedStatus", options),
		setSelectedState: (options) => updateFilter(set)("selectedState", options),
		setSelectedCity: (options) => updateFilter(set)("selectedCity", options),
		setSelectedMeter: (options) => updateFilter(set)("selectedMeter", options),
		setSelectedPiiStatus: (options) =>
			updateFilter(set)("selectedPiiStatus", options),
		setSelectedCoordinator: (options) =>
			updateFilter(set)("selectedCoordinator", options),
		setSelectedSafeWorkHeightCoordinator: (options) =>
			updateFilter(set)("selectedSafeWorkHeightCoordinator", options),
		setSelectedSafeWorkHeight: (options) =>
			updateFilter(set)("selectedSafeWorkHeight", options),
		setRoleOpera: (role) => updateFilter(set)("isRoleOpera", role),
		resetFilters: () => set(initialState),
		resetFiltersOpera: () =>
			set({
				selectedPiiStatus: [],
				selectedCoordinator: [],
				selectedSafeWorkHeightCoordinator: [],
				selectedSafeWorkHeight: [],
			}),
	}),
);
