import {
	Grid,
	IconButton,
	ArrowCircleLeftOutlinedIcon,
	Typography,
	Stack,
} from "@enerbit/base";
import { Link, useParams } from "react-router-dom";
import { EditUserForm } from "../components/user";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useUserStore } from "../store";
import { IUserItem } from "../interfaces";
import { EditUserSkeleton } from "../components/skeleton";

export const EditUser = () => {
	const { id } = useParams();
	const [userData, setUserData] = useState<IUserItem | null>(null);
	const [isUserLoading, setIsUserLoading] = useState(false);

	const { getUser } = useUserStore((state) => state);

	useEffect(() => {
		const setUser = async () => {
			if (id) {
				try {
					setIsUserLoading(true);
					const data = await getUser(id);
					setUserData(data);
				} catch (error) {
					console.log(error);
					enqueueSnackbar((error as Error).message, { variant: "error" });
				} finally {
					setIsUserLoading(false);
				}
			}
		};
		setUser();
	}, [id]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Stack spacing={3} direction="row" alignItems={"center"}>
					<Link to="/users-companies" style={{ textDecoration: "none" }}>
						<IconButton color="primary" size="small" sx={{ p: 0 }}>
							<ArrowCircleLeftOutlinedIcon fontSize="large" />
						</IconButton>
					</Link>
					<Typography
						color="primary"
						sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
					>
						Editar usuario
					</Typography>
				</Stack>
			</Grid>
			<Grid item xs={12}>
				{!isUserLoading && userData ? (
					<EditUserForm userData={userData} />
				) : (
					<EditUserSkeleton />
				)}
			</Grid>
		</Grid>
	);
};
