import { Grid } from "@enerbit/base";
import { SearchCompany } from "../search";
import { HeaderCompany } from "../header";
import { InfoCompany, HeaderContract } from ".";
import { useCompanyStore, useJWTStore } from "../../store";
import { useEffect } from "react";
import {
	FileCompanyItemSkeleton,
	HeaderContractSkeleton,
	InfoCompanySkeleton,
} from "../skeleton";

export const Company = () => {
	const { company, getCompany } = useCompanyStore((state) => state);
	const { decodedToken } = useJWTStore();

	useEffect(() => {
		if (decodedToken?.role === "contratista") {
			getCompany(decodedToken.company_id);
		}
	}, [decodedToken]);

	return (
		<Grid container item xs={12}>
			<HeaderCompany />
			<Grid container spacing={3}>
				{!(decodedToken?.role === "contratista") && <SearchCompany />}
				{company && (
					<>
						<InfoCompany />
						<HeaderContract />
					</>
				)}
				{!company && decodedToken?.role === "contratista" && (
					<>
						<InfoCompanySkeleton />
						<HeaderContractSkeleton />
						{new Array(6).fill(0).map((_, index) => {
							return <FileCompanyItemSkeleton key={index} />;
						})}
					</>
				)}
			</Grid>
		</Grid>
	);
};
