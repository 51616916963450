import { Chip } from "@enerbit/base";
import type { FC } from "react";

interface Props {
	status: boolean;
	type: "user" | "doc";
}

export const StatusUser: FC<Props> = ({ status, type }) => {
	return (
		<>
			{status ? (
				<Chip
					label={type === "user" ? "Activo" : "Cumple documentación"}
					sx={{
						background: "#E7F8F0",
						color: "#12B76A",
						fontWeight: "bold",
					}}
				/>
			) : (
				<Chip
					label={type === "user" ? "Inactivo" : "No cumple documentación"}
					sx={{
						background: "#FEECEB",
						color: "#F04438",
						fontWeight: "bold",
					}}
				/>
			)}
		</>
	);
};
