import {
	Box,
	Button,
	CloseIcon,
	EditIcon,
	Grid,
	IconButton,
	Typography,
	capitalizeFirstLetter,
} from "@enerbit/base";
import { useState } from "react";
import { StyledIconButton } from "../../common/StyledIconButton";
import { EditCompany } from "../../pages";
import { useCompanyStore, useJWTStore } from "../../store";

export const InfoCompany = () => {
	const { company, clearCompany } = useCompanyStore();
	const { decodedToken } = useJWTStore();

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Grid item container spacing={3}>
			<Grid item xs={12}>
				<Box display={"flex"} justifyContent={"space-between"}>
					<Typography
						color="primary"
						sx={{ fontSize: "21px", fontWeight: 700 }}
					>
						{capitalizeFirstLetter(company?.name ?? "")}
					</Typography>
					{!(decodedToken?.role === "contratista") && (
						<StyledIconButton
							color="inherit"
							size="small"
							onClick={clearCompany}
						>
							<CloseIcon />
						</StyledIconButton>
					)}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						gap: 3,
					}}
				>
					<Box>
						<Typography sx={{ fontWeight: "light" }}>NIT:</Typography>
						<Typography sx={{ fontWeight: "bold" }}>
							{company?.legal_id_code}
						</Typography>
					</Box>
					<Box>
						<Typography sx={{ fontWeight: "light" }}>
							Correo electrónico:
						</Typography>
						<Typography sx={{ fontWeight: "bold" }}>
							{company?.email}
						</Typography>
					</Box>
					<Box>
						<Typography sx={{ fontWeight: "light" }}>
							Dirección de la empresa:
						</Typography>
						<Typography sx={{ fontWeight: "bold" }}>
							{company?.address}
						</Typography>
					</Box>
					<Box>
						<Typography sx={{ fontWeight: "light" }}>Teléfono:</Typography>
						<Typography sx={{ fontWeight: "bold" }}>
							{company?.phone}
						</Typography>
					</Box>
					<Box>
						<StyledIconButton color="primary" onClick={handleOpen}>
							<EditIcon />
						</StyledIconButton>
						<EditCompany open={open} handleClose={handleClose} />
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
