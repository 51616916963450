import {
	Grid,
	FormControl,
	TextField,
	InputAdornment,
	CircularProgress,
} from "@enerbit/base";
import { useCompanyStore, useContractStore } from "../../store";
import { ChangeEvent, useEffect } from "react";
import { useDebounce } from "use-debounce";

export const DynamicSearchCompany = () => {
	const {
		search: value,
		setSearch,
		getContracts,
		loading,
		pagination,
	} = useContractStore((state) => state);
	const { company } = useCompanyStore();

	const [debouncedValue] = useDebounce(value, 700); // Ajusta el tiempo (500 ms) según sea necesario

	const handleSubSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setSearch(newValue);
	};

	useEffect(() => {
		if (company) {
			getContracts(company?.id, value, pagination.page);
		}
	}, [company?.id, debouncedValue, pagination.page]);

	return (
		<Grid item xs={12}>
			<FormControl fullWidth>
				<TextField
					fullWidth
					name={"search"}
					value={value}
					onChange={handleSubSearchChange}
					sx={{ "& fieldset": { borderRadius: "14px" }, height: "auto" }}
					type={"text"}
					id={`contract-search`}
					disabled={loading}
					variant="outlined"
					placeholder={`Buscar contrato por código`}
					InputProps={{
						endAdornment: loading && (
							<InputAdornment position="end">
								<CircularProgress size={20} thickness={6} />
							</InputAdornment>
						),
					}}
				/>
			</FormControl>
		</Grid>
	);
};
