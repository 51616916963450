import { Grid, Box, Pagination } from "@enerbit/base";
import { useContractStore } from "../../store";
import { EmptyData } from "../empty-data";
import { ChangeEvent } from "react";

export const PaginationCompany = () => {
	const { setPage, loading, listContract } = useContractStore((state) => state);
	const { total, page, size } = useContractStore((state) => state.pagination);

	const handlePageChange = (_: ChangeEvent<unknown>, value: number): void => {
		setPage(value - 1);
	};

	const totalPages = Math.ceil(total / size);

	return (
		<Grid item xs={12}>
			{listContract.length === 0 && !loading ? (
				<EmptyData message="No se encontraron contratos asociados a esta empresa." />
			) : (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Pagination
						count={totalPages}
						page={page + 1}
						onChange={handlePageChange}
						shape="rounded"
					/>
				</Box>
			)}
		</Grid>
	);
};
