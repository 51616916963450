import dayjs from "dayjs";

export const convertDatesToISO = (obj: any): any => {
	// Verifica si un string corresponde a una fecha en el formato YYYY-MM-DD
	const isDateString = (str: string) => /^(\d{4})-(\d{2})-(\d{2})$/.test(str);

	if (obj instanceof Date) {
		return dayjs(obj).toISOString();
	}

	if (typeof obj === "string" && isDateString(obj)) {
		return dayjs(obj).toISOString();
	}

	if (Array.isArray(obj)) {
		return obj.map((item) => convertDatesToISO(item));
	}

	if (typeof obj === "object" && obj !== null) {
		const newObj: { [key: string]: any } = {};
		Object.keys(obj).forEach((key) => {
			newObj[key] = convertDatesToISO(obj[key]);
		});
		return newObj;
	}

	return obj;
};
