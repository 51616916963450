import {
	Grid,
	InputLabel,
	enerbitColors,
	FormControlLabel,
	Switch,
	Typography,
	Box,
} from "@enerbit/base";
import { FC, ChangeEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IDynamicForm } from "../../../interfaces";
import { FormItem } from "..";
import { useExpirationNotification } from "../../../hooks";

export const SwitchForm: FC<IDynamicForm> = ({
	name,
	label,
	sx,
	children,
	json,
	props,
}) => {
	const { control, setValue, watch } = useFormContext();

	const watchedValue = useWatch({ name: name!, control });

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;
		if (json) {
			if (checked) {
				setValue(name!, json);
			} else {
				// Elimina '.0' del final si existe y establece el valor en null
				const updatedName = name?.endsWith(".0") ? name.slice(0, -2) : name;
				setValue(updatedName!, null);
			}
		} else {
			setValue(name!, checked ? checked : null);
		}
	};

	const expirationField = props?.expiration?.expirationField
		? watch(props.expiration?.expirationField)
		: null;

	const { showNotificationExpiration, expirationMessage } =
		useExpirationNotification({
			expirationField,
			notifyMonthsBefore: props?.expiration?.notifyMonthsBefore,
			notifyDaysBefore: props?.expiration?.notifyDaysBefore,
		});

	return (
		<Grid container item spacing={3} xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<Grid item>
				<Box sx={{ display: "flex", gap: 2 }}>
					<InputLabel
						sx={{
							display: "flex",
							alignItems: "end",
							color: enerbitColors.primary.main,
							fontWeight: "bold",
						}}
					>
						{label}
					</InputLabel>
					{showNotificationExpiration && (
						<Typography
							sx={{
								color: enerbitColors.warning.main,
								fontWeight: 500,
								fontSize: "14px",
								marginLeft: "8px",
							}}
						>
							{expirationMessage}
						</Typography>
					)}
				</Box>
				<FormControlLabel
					control={
						<Switch
							id={`${name}`}
							disabled={props?.disabled}
							color="secondary"
							checked={!!watchedValue || false}
							onChange={handleChange}
						/>
					}
					label={!!watchedValue ? "Si" : "No"}
				/>
			</Grid>
			<Grid container item spacing={3}>
				{children &&
					watch(name!) &&
					children?.map((item, i) => {
						const currentField = {
							...item,
							name: `${name}${item.name ? "." + item.name : ""}`,
							props: { disabled: props?.disabled, ...item.props },
						};
						return <FormItem {...currentField} key={i} />;
					})}
			</Grid>
		</Grid>
	);
};
