export const cleanObject = (formData: any): any => {
	// Función para determinar si todos los hijos son vacíos (cadenas vacías o null).
	const allChildrenAreEmptyOrNull = (child: any): boolean => {
		if (Array.isArray(child)) {
			return child.length === 0 || child.every(allChildrenAreEmptyOrNull);
		} else if (typeof child === "object" && child !== null) {
			return (
				Object.keys(child).length === 0 ||
				Object.values(child).every(
					(value) => value === "" || allChildrenAreEmptyOrNull(value),
				)
			);
		}
		return child === "";
	};

	// Función para limpiar el objeto formData, aplicando la regla de conversión a null si los hijos están vacíos o son null.
	const cleanedFormData: any = {} as any;

	Object.keys(formData).forEach((key) => {
		const child = formData[key];
		// Aplica la conversión a null si todos los hijos son vacíos o null.
		if (allChildrenAreEmptyOrNull(child)) {
			cleanedFormData[key] = null;
		} else {
			cleanedFormData[key] = child; // Conserva el hijo como está si no cumple la condición.
		}
	});

	return cleanedFormData;
};
