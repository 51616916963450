import { z } from "zod";

export const PostCompanySchema = z.object({
	name: z.string().min(1, "Este campo es obligatorio."),
	legal_id_code: z.string().min(1, "Este campo es obligatorio."),
	address: z.string().min(1, "Este campo es obligatorio."),
	phone: z.string().min(1, "Este campo es obligatorio."),
	email: z
		.string()
		.min(1, "Este campo es obligatorio.")
		.email("Correo electrónico invalido."),
});
