import { Box, Typography, Button, AddIcon, Grid } from "@enerbit/base";
import { useState } from "react";
import { CreateCompany } from "../../pages";
import { useJWTStore } from "../../store";

export const HeaderCompany = () => {
	const { decodedToken } = useJWTStore();

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Grid item xs={12}>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography
					color="primary"
					sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
				>
					Empresa{!(decodedToken?.role === "contratista") ? "s" : ""}
				</Typography>
				{!(decodedToken?.role === "contratista") && (
					<>
						<Button
							onClick={handleOpen}
							id="create-company"
							color="secondary"
							variant="contained"
							startIcon={<AddIcon />}
						>
							Crear empresa
						</Button>
						<CreateCompany open={open} handleClose={handleClose} />
					</>
				)}
			</Box>
		</Grid>
	);
};
