import { Grid, Box, Pagination } from "@enerbit/base";
import { useUserStore } from "../../store";
import { EmptyData } from "../empty-data";
import { ChangeEvent, FC } from "react";

export const PaginationUser: FC = () => {
	const { loadingUsers, setPage, listUsers } = useUserStore((state) => state);
	const { total, page, size } = useUserStore((state) => state.pagination);

	const handlePageChange = (_: ChangeEvent<unknown>, value: number): void => {
		setPage(value - 1);
	};

	const totalPages = Math.ceil(total / size);

	const siblingCount = Math.max(1, Math.min(Math.floor(totalPages / 10), 3));
	const boundaryCount = Math.max(1, Math.min(Math.floor(totalPages / 15), 2));

	return (
		<Grid item xs={12}>
			{listUsers.length === 0 && !loadingUsers ? (
				<EmptyData message="No se encontraron" />
			) : (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Pagination
						disabled={loadingUsers}
						count={totalPages}
						page={page + 1}
						onChange={handlePageChange}
						shape="rounded"
						siblingCount={siblingCount}
						boundaryCount={boundaryCount}
					/>
				</Box>
			)}
		</Grid>
	);
};
