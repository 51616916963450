import { Grid, Box, Stack, Skeleton } from "@enerbit/base";
import { FC } from "react";

export const UserItemSkeleton: FC = () => {
	return (
		<Grid item xs={12} lg={4} md={6}>
			<Box
				sx={{
					p: 3,
					border: "1px solid #E4E7EC",
					borderRadius: "12px",
					color: "#667085",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Grid container spacing={3}>
						<Grid item>
							<Skeleton variant="circular" width={50} height={50} />
						</Grid>
						<Grid item sx={{ display: "flex", flexDirection: "column" }}>
							<Skeleton variant="text" width={150} height={25} />
							<Skeleton variant="text" width={140} height={15} />
						</Grid>
					</Grid>
					<Skeleton
						variant="rectangular"
						width={45}
						height={40}
						sx={{ borderRadius: "20%" }}
					/>
				</Box>
				<Box>
					<Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
						{[...Array(4)].map((_, index) => (
							<Skeleton
								key={index}
								variant="rectangular"
								width={80}
								height={24}
								sx={{ borderRadius: "10%" }}
							/>
						))}
					</Stack>
				</Box>
			</Box>
		</Grid>
	);
};
