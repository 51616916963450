import { Grid, Box, Skeleton, Stack } from "@enerbit/base";

export const FileCompanyItemSkeleton = () => {
	return (
		<Grid item xs={12} lg={4} md={6}>
			<Box
				sx={{
					p: 3,
					border: "1px solid #E4E7EC",
					borderRadius: "12px",
					color: "#667085",
					display: "flex",
					height: "100%", // Asegura que tome toda la altura disponible
					width: "100%",
				}}
			>
				<Box width={"70%"} display="flex">
					<Grid container columnSpacing={2}>
						<Grid item>
							<Skeleton variant="rounded" width={45} height={50} />{" "}
							{/* Skeleton para la caja del PDF */}
						</Grid>
						<Grid item>
							<Skeleton variant="text" width={100} height={25} />{" "}
							{/* Skeleton para el título del documento */}
							<Skeleton variant="text" width={125} height={20} />{" "}
							{/* Skeleton para la fecha del documento */}
						</Grid>
					</Grid>
				</Box>
				<Stack
					direction="row"
					spacing={1}
					sx={{
						width: "30%",
						justifyContent: "end",
						alignItems: "center",
					}}
				>
					<Skeleton variant="rounded" width={40} height={40} />{" "}
					{/* Skeleton para el botón de edición */}
				</Stack>
			</Box>
		</Grid>
	);
};
