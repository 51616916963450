import { Box, Grid, Typography } from "@enerbit/base";
import { useEffect } from "react";
import { useCompanyStore, useJWTStore, useUserStore } from "../../store";
import { useFilterUserStore } from "../../store/filter";
import { FiltersUser } from "../filters";
import { SearchUser } from "../search";
import { HeaderUser } from "./HeaderUser";
import { UserList } from "./UserList";

export const User = () => {
	const { decodedToken } = useJWTStore();

	const { getUsers, pagination, search, departments } = useUserStore();

	const { page } = pagination;

	const {
		selectedCompanies,
		isRoleOpera,
		selectedCity,
		selectedMeter,
		selectedPiiStatus,
		selectedRoles,
		selectedState,
		selectedStatus,
		selectedCoordinator,
		selectedSafeWorkHeightCoordinator,
		selectedSafeWorkHeight,
		setSelectedCity,
		setSelectedMeter,
		setRoleOpera,
		resetFiltersOpera,
	} = useFilterUserStore();

	useEffect(() => {
		if (decodedToken) {
			setRoleOpera(
				selectedRoles.includes("OPR") ||
					selectedRoles.includes("operacion_energia_digital"),
			);
			const status =
				selectedStatus.length > 0 ? selectedStatus[0] === "true" : undefined;
			const piiStatus =
				selectedPiiStatus.length > 0
					? selectedPiiStatus[0] === "true"
					: undefined;
			const state = departments.find(
				({ value }) => value === selectedState[0],
			)?.text;

			const isCoordinator =
				selectedCoordinator.length > 0
					? selectedCoordinator[0] === "true"
					: undefined;

			const isSafeWorkHeightCoordinator =
				selectedSafeWorkHeightCoordinator.length > 0
					? selectedSafeWorkHeightCoordinator[0] === "true"
					: undefined;

			const isSafeWorkHeight =
				selectedSafeWorkHeight.length > 0
					? selectedSafeWorkHeight[0] === "true"
					: undefined;

			const companyID =
				decodedToken?.role === "contratista"
					? decodedToken.company_id
					: selectedCompanies[0];

			getUsers(
				page,
				status,
				selectedRoles[0],
				search,
				piiStatus,
				state,
				selectedCity[0],
				selectedMeter.join(","),
				companyID,
				isCoordinator,
				isSafeWorkHeightCoordinator,
				isSafeWorkHeight,
			);
		}
	}, [
		page,
		selectedStatus,
		selectedRoles,
		search,
		selectedPiiStatus,
		selectedState,
		selectedCity[0],
		selectedMeter,
		decodedToken,
		selectedCompanies,
		selectedCoordinator,
		selectedSafeWorkHeightCoordinator,
		selectedSafeWorkHeight,
		departments,
		getUsers,
		setRoleOpera
	]);

	useEffect(() => {
		if (isRoleOpera) {
			setSelectedMeter([]);
		} else {
			resetFiltersOpera();
		}
	}, [isRoleOpera, resetFiltersOpera, setSelectedMeter]);

	useEffect(() => {
		setSelectedCity([]);
	}, [selectedState, setSelectedCity]);

	return (
		<Grid container item xs={12}>
			<HeaderUser />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography>Consulta por correo electrónico</Typography>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
						<SearchUser />
						<FiltersUser />
					</Box>
				</Grid>
				<UserList />
			</Grid>
		</Grid>
	);
};
