import { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/es";
import { IExpiration } from "../interfaces";

dayjs.extend(relativeTime);
dayjs.locale("es");

export const useExpirationNotification = ({
	expirationField,
	notifyMonthsBefore = 0,
	notifyDaysBefore = 0,
}: IExpiration) => {
	const [showNotificationExpiration, setShowNotification] = useState(false);
	const [expirationMessage, setExpirationMessage] = useState("");

	useEffect(() => {
		if (expirationField) {
			const expirationDate = dayjs(expirationField).endOf("day");
			const currentDate = dayjs();

			const monthsToExpiration = expirationDate.diff(
				currentDate,
				"month",
				true,
			);
			const daysToExpiration = expirationDate.diff(currentDate, "day", true);

			let shouldNotify = false;

			if (notifyMonthsBefore > 0) {
				shouldNotify =
					monthsToExpiration <= notifyMonthsBefore &&
					monthsToExpiration >= notifyMonthsBefore - 1;
			}

			if (notifyDaysBefore > 0) {
				shouldNotify =
					shouldNotify ||
					(daysToExpiration <= notifyDaysBefore &&
						daysToExpiration >= notifyDaysBefore - 1);
			}

			if (daysToExpiration < 0) {
				shouldNotify = true;
				setExpirationMessage(
					`Se venció hace ${expirationDate.from(currentDate, true)}!`,
				);
			} else if (daysToExpiration <= 0) {
				shouldNotify = true;
				setExpirationMessage(`Se vence hoy`);
			} else {
				setExpirationMessage(
					`Se vence en ${expirationDate.from(currentDate, true)}`,
				);
			}

			setShowNotification(shouldNotify);
		}
	}, [expirationField, notifyMonthsBefore, notifyDaysBefore]);

	return { showNotificationExpiration, expirationMessage };
};
