import { Grid } from "@enerbit/base";
import { FileCompanyItem } from ".";
import { useContractStore } from "../../store";
import { FileCompanyItemSkeleton } from "../skeleton";

export const FileCompanyList = () => {
	const { listContract, loading } = useContractStore((state) => state);

	return (
		<Grid
			container
			item
			spacing={3}
			style={{ display: "flex", alignItems: "stretch" }}
		>
			{loading ? (
				<>
					{new Array(6).fill(0).map((_, index) => {
						return <FileCompanyItemSkeleton key={index} />;
					})}
				</>
			) : (
				<>
					{listContract.map((item, index) => {
						return <FileCompanyItem {...item} key={index} />;
					})}
				</>
			)}
		</Grid>
	);
};
