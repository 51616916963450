import {
	Grid,
	Autocomplete,
	TextField,
	CircularProgress,
	FormControl,
	InputLabel,
} from "@enerbit/base";
import { useState, useEffect, FC } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { getCities, getCountries, getDepartments } from "../../services";
import { IItemsJson } from "../../interfaces";

interface Props {
	nameCountry: string;
	nameState: string;
	nameCity: string;
}

export const DynamicLocationForm: FC<Props> = ({
	nameCountry,
	nameState,
	nameCity,
}) => {
	const {
		control,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	const [countryOptions, setCountryOptions] = useState<IItemsJson[]>([]);
	const [stateOptions, setStateOptions] = useState<IItemsJson[]>([]);
	const [cityOptions, setCityOptions] = useState<IItemsJson[]>([]);

	const [isLoadingCountries, setIsLoadingCountries] = useState(false);
	const [isLoadingStates, setIsLoadingStates] = useState(false);
	const [isLoadingCities, setIsLoadingCities] = useState(false);

	const selectedCountry = watch(nameCountry);
	const selectedState = watch(nameState);
	const selectedCity = watch(nameCity);

	useEffect(() => {
		const loadCountries = async () => {
			setIsLoadingCountries(true);
			const countries = await getCountries();
			setIsLoadingCountries(false);
			setCountryOptions(countries);
		};
		loadCountries();
	}, []);

	useEffect(() => {
		const loadStates = async () => {
			if (selectedCountry) {
				setIsLoadingStates(true);
				const states = await getDepartments();
				setIsLoadingStates(false);
				setStateOptions(states);
			} else {
				setStateOptions([]);
			}
		};
		loadStates();
	}, [selectedCountry]);

	useEffect(() => {
		const loadCities = async () => {
			if (selectedState) {
				const value = stateOptions.find(
					({ text }) => text === selectedState,
				)?.value;
				setIsLoadingCities(true);
				const cities = await getCities(value?.toString() ?? "");
				setIsLoadingCities(false);
				setCityOptions(cities);
			} else {
				setCityOptions([]);
			}
		};
		loadCities();
	}, [selectedState, stateOptions]);

	const handleCountryChange = (value: any) => {
		setValue(nameCountry, value ? value.value : "");
		setValue(nameState, ""); // Reset state field
		setValue(nameCity, ""); // Reset city field
	};

	const handleStateChange = (value: any) => {
		setValue(nameState, value ? value.text : "");
		setValue(nameCity, ""); // Reset city field
	};

	return (
		<>
			<Grid item xs={12} sm={6} lg={3}>
				<InputLabel>País</InputLabel>
				<FormControl fullWidth>
					<Controller
						name={nameCountry}
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								{...field}
								id={nameCountry}
								fullWidth
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								options={countryOptions}
								getOptionLabel={(option) => option.text}
								loading={isLoadingCountries}
								loadingText="Cargando..."
								noOptionsText="Sin opciones."
								onChange={(_, value) => handleCountryChange(value)}
								value={
									countryOptions.find(
										(option) => option.value === selectedCountry,
									) || null
								}
								renderInput={(params) => {
									const { InputLabelProps, ...rest } = params;

									return (
										<TextField
											{...rest}
											fullWidth
											error={!!get(errors, nameCountry)}
											helperText={
												!!get(errors, nameCountry) &&
												get(errors, nameCountry).message
											}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{isLoadingCountries ? (
															<CircularProgress size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									);
								}}
							/>
						)}
					/>
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={6} lg={3}>
				<InputLabel>Departamento</InputLabel>
				<FormControl fullWidth>
					<Controller
						name={nameState}
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								{...field}
								id={nameState}
								readOnly={!selectedCountry}
								fullWidth
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								options={stateOptions}
								getOptionLabel={(option) => option.text}
								loading={isLoadingStates}
								loadingText="Cargando..."
								noOptionsText="Sin opciones."
								onChange={(_, value) => handleStateChange(value)}
								value={
									stateOptions.find(
										(option) => option.text === selectedState,
									) || null
								}
								renderInput={(params) => {
									const { InputLabelProps, ...rest } = params;

									return (
										<TextField
											{...rest}
											fullWidth
											error={!!get(errors, nameState)}
											helperText={
												!!get(errors, nameState) &&
												get(errors, nameState).message
											}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{isLoadingStates ? (
															<CircularProgress size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									);
								}}
							/>
						)}
					/>
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={6} lg={3}>
				<InputLabel>Ciudad</InputLabel>
				<FormControl fullWidth>
					<Controller
						name={nameCity}
						control={control}
						render={({ field }) => (
							<Autocomplete
								{...field}
								id={nameCity}
								readOnly={!selectedState}
								fullWidth
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								options={cityOptions}
								getOptionLabel={(option) => option.text}
								loading={isLoadingCities}
								loadingText="Cargando..."
								noOptionsText="Sin opciones."
								onChange={(_, value) =>
									setValue(nameCity, value ? value.text : "")
								}
								value={
									cityOptions.find((option) => option.text === selectedCity) ||
									null
								}
								renderInput={(params) => {
									const { InputLabelProps, ...rest } = params;

									return (
										<TextField
											{...rest}
											fullWidth
											error={!!get(errors, nameCity)}
											helperText={
												!!get(errors, nameCity) && get(errors, nameCity).message
											}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{isLoadingCities ? (
															<CircularProgress size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									);
								}}
							/>
						)}
					/>
				</FormControl>
			</Grid>
		</>
	);
};
