import {
	Grid,
	InputLabel,
	FormControl,
	Autocomplete,
	TextField,
	CircularProgress,
} from "@enerbit/base";
import { FC, useState, useEffect } from "react";
import { useFormContext, Controller, get } from "react-hook-form";
import { IDynamicForm, IItemsJson } from "../../../interfaces";

export const AutocompleteForm: FC<IDynamicForm> = ({
	name,
	label,
	fetchItems,
	dependencyField,
	sx,
}) => {
	const {
		control,
		watch,
		resetField,
		formState: { errors },
	} = useFormContext();
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<IItemsJson[]>([]);
	const dependentValue = dependencyField ? watch(dependencyField) : "";
	const loading = open && options.length === 0;

	const [key, setKey] = useState(0); // Estado para forzar el re-renderizado

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			if (fetchItems && active) {
				const fetchedItems = dependencyField
					? await fetchItems(dependentValue)
					: await fetchItems();
				setOptions(fetchedItems);
			}
		})();

		return () => {
			active = false;
		};
	}, [loading, fetchItems, dependentValue, dependencyField]);

	useEffect(() => {
		if (dependencyField) {
			resetField(name!);
			setKey((prevKey) => prevKey + 1);
		}
	}, [dependentValue, dependencyField, name]);

	useEffect(() => {
		if (name && !watch(name) && !dependencyField) {
			resetField(name!);
			setKey((prevKey) => prevKey + 1);
		}
	}, [name, watch(name!), dependencyField]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<InputLabel>{label}</InputLabel>
			<FormControl fullWidth>
				<Controller
					key={key} // Usa la clave para forzar el re-renderizado
					name={name!}
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<Autocomplete
							{...field}
							id={name!}
							defaultValue={
								options.find((option) => option.value === value) || null
							}
							onChange={(_, data) => onChange(data ? data.value : "")}
							readOnly={!!dependencyField && !watch(dependencyField!)}
							fullWidth
							sx={{ "& fieldset": { borderRadius: "14px" } }}
							open={open}
							onOpen={() => setOpen(true)}
							onClose={() => setOpen(false)}
							getOptionLabel={(option) => option.text}
							options={options}
							loading={loading}
							loadingText="Cargando..."
							noOptionsText="Sin opciones."
							renderInput={(params) => {
								const { InputLabelProps, ...rest } = params;

								return (
									<TextField
										{...rest}
										fullWidth
										error={!!get(errors, name)}
										helperText={
											!!get(errors, name) && get(errors, name).message
										}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{loading ? <CircularProgress size={20} /> : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								);
							}}
						/>
					)}
				/>
			</FormControl>
		</Grid>
	);
};
