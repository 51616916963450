import {
	Grid,
	FormControl,
	TextField,
	InputAdornment,
	CircularProgress,
} from "@enerbit/base";
import { useUserStore } from "../../store";
import { useEffect, ChangeEvent } from "react";
import { useDebounce } from "use-debounce";

export const SearchUser = () => {
	const { setSearch, loading, debounceSearch, setDebounceSearch } =
		useUserStore((state) => state);
	const [debouncedValue] = useDebounce(debounceSearch, 500); // 500ms de debounce

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setDebounceSearch(event.target.value);
	};

	useEffect(() => {
		setSearch(debouncedValue);
	}, [debouncedValue, setSearch]);

	return (
		<div style={{ width: "100%" }}>
			<FormControl fullWidth>
				<TextField
					fullWidth
					name={"search"}
					value={debounceSearch}
					onChange={handleInputChange}
					sx={{ "& fieldset": { borderRadius: "14px" } }}
					type={"text"}
					id={"user-search"}
					disabled={loading}
					variant="outlined"
					placeholder="Buscar"
					InputProps={{
						endAdornment: loading && (
							<InputAdornment position="end">
								<CircularProgress size={20} thickness={6} />
							</InputAdornment>
						),
					}}
				/>
			</FormControl>
		</div>
	);
};
