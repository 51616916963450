import {
	IPostContract,
	IOtherDocument,
	IInvoice,
	ICancelledContract,
} from "../interfaces";

export const defaultValuesContract: IPostContract = {
	description: "",
	code: "",
	form_data: {
		path_contract: [], //
		path_initial_record: "", //
		records: null, //
		path_technical_specifications: null, //
		ppe_document: null, //
		invoice: null, //
		policy: null, //
		observations: null,
		cancelled_contract: null,
		other_documents: null, //
	},
	value: 0,
	started_at: "",
	ended_at: "",
	company_id: "",
};

export const defaultValuesRecordsContract: IOtherDocument[] = [
	{
		url_path: "",
		name: "",
	},
];

export const defaultValuesInvoiceContract: IInvoice[] = [
	{
		url_path: "",
		value: 0,
		started_at: "",
		ended_at: "",
	},
];

export const defaultValuesCancelledContract: ICancelledContract = {
	observation: "",
	url_path: "",
};
