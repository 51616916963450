import {
	Grid,
	InputLabel,
	enerbitColors,
	FormControlLabel,
	Switch,
} from "@enerbit/base";
import { useFormContext } from "react-hook-form";
import { ChangeEvent, FC } from "react";
import { createDefaultCertification } from "../../form-defaults";
import { FormItem } from "../dynamic-form";
import { formCreateCertificationOperator } from "../../form-builders";

interface Props {
	name: string;
	currentRol: string;
}

export const DynamicVehicleForm: FC<Props> = ({ name, currentRol }) => {
	const { setValue, watch } = useFormContext();
	const drive_license = watch(`${name}.drive_license.0`);
	const isActive = !!drive_license;

	const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
		const nuevoEstado = event.target.checked;
		if (nuevoEstado) {
			if (!drive_license)
				setValue(`${name}.drive_license.0`, createDefaultCertification());
		} else {
			setValue(`${name}.drive_license`, null);
		}
	};

	return (
		<>
			{currentRol !== "operacion_energia_digital" && (
				<Grid container item spacing={3} xs={12}>
					<Grid item>
						<InputLabel
							sx={{
								display: "flex",
								alignItems: "end",
								color: enerbitColors.primary.main,
								fontWeight: "bold",
							}}
						>
							¿Conduce vehículo de la empresa?
						</InputLabel>
						<FormControlLabel
							control={
								<Switch
									id="vehicle-switch"
									checked={isActive}
									onChange={handleSwitchChange}
									color="secondary"
								/>
							}
							label={!!isActive ? "Si" : "No"}
						/>
					</Grid>
				</Grid>
			)}
			{(currentRol === "operacion_energia_digital" || isActive) && (
				<Grid container item xs={12} spacing={3}>
					<FormItem
						type={"accordion"}
						label={"Licencia de conducción"}
						name={`${name}.drive_license.0`}
						children={formCreateCertificationOperator}
						props={{
							expiration: {
								expirationField: `${name}.drive_license.0.expiration_certification`,

								notifyMonthsBefore: 2,
							},
						}}
					/>
				</Grid>
			)}
		</>
	);
};
