import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	LoadingButton,
	WarningIcon,
} from "@enerbit/base";
import type { FC } from "react";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { useTeamStore } from "../../store/team";

interface ConfirmDialogProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

export const EditUserDialog: FC<ConfirmDialogProps> = ({
	open,
	onClose,
	onConfirm,
}) => {
	const { loadingTeams } = useTeamStore();

	return (
		<StyledDialog open={open}>
			<StyledDialogTitle onClose={onClose} id={"id-confirm-edit"} />
			<DialogContent>
				<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
					<WarningIcon
						color="secondary"
						sx={{ textAlign: "center" }}
						fontSize={"large"}
					/>
				</Box>
				<DialogContentText sx={{ color: "#53358E", textAlign: "center" }}>
					¿Está seguro que desea suspender este usuario?
				</DialogContentText>
				<DialogContentText
					sx={{
						color: "#53358E",
						fontWeight: "bold",
						fontSize: "18px",
						textAlign: "center",
					}}
				>
					Recuerde que esta acción es irreversible y no podrá modificarla.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					fullWidth
					onClick={onConfirm}
					color="secondary"
					variant="contained"
					loading={loadingTeams}
				>
					Aceptar
				</LoadingButton>
				<Button fullWidth onClick={onClose} color="primary" variant="outlined">
					Cancelar
				</Button>
			</DialogActions>
		</StyledDialog>
	);
};
