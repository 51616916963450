import { decodedJwt } from "@enerbit/base";
import create from "zustand";

export interface JwtPayload {
	sub: string;
	full_name: string;
	name: string;
	role: string;
	company_id: string;
	company_name: string;
	exp: number;
}

interface StoreState {
	decodedToken: JwtPayload | null;
	loadAndDecodeJwt: () => void;
}

export const useJWTStore = create<StoreState>()((set) => ({
	decodedToken: null,
	loadAndDecodeJwt: () => {
		const decoded: JwtPayload = decodedJwt() as any;
		try {
			if (decoded) {
				set({ decodedToken: decoded });
			} else {
				set({ decodedToken: null });
			}
		} catch (error) {
			console.error("Error al decodificar JWT", error);
			set({ decodedToken: null });
		}
	},
}));
