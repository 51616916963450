import {
	Grid,
	enerbitColors,
	ExpandCircleDownOutlinedIcon,
	Avatar,
	CloseIcon,
	AddIcon,
	Typography,
} from "@enerbit/base";
import { FC, SyntheticEvent, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import {
	StyledAccordion2,
	StyledAccordionSummary2,
	StyledAccordionDetails2,
} from "../../common";
import { FormItem } from "../dynamic-form";
import { formPersonalInformation } from "../../form-builders";

interface Props {
	name: string;
}

export const PersonalInformation: FC<Props> = ({ name }) => {
	const {
		formState: { errors },
	} = useFormContext();

	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChange =
		(panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	const hasAccordionErrors = () => {
		return formPersonalInformation.some((field) => {
			const fieldName = `${name}.${field.name}`;
			return !!get(errors, fieldName);
		});
	};

	return (
		<Grid item xs={12}>
			<StyledAccordion2
				expanded={expanded === name}
				onChange={handleChange(name!)}
				sx={{
					borderColor: hasAccordionErrors()
						? enerbitColors.error.main
						: undefined,
				}}
				id={name}
			>
				<StyledAccordionSummary2
					expandIcon={
						<ExpandCircleDownOutlinedIcon
							sx={{
								fontSize: "1.5rem",
								color: hasAccordionErrors()
									? enerbitColors.error.main
									: undefined,
							}}
						/>
					}
				>
					<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
						<Avatar
							color="primary"
							sx={{
								borderRadius: "50%",
								bgcolor: hasAccordionErrors()
									? enerbitColors.error.main
									: enerbitColors.primary.main,
								width: 24,
								height: 24,
							}}
						>
							{hasAccordionErrors() ? <CloseIcon /> : <AddIcon />}
						</Avatar>
						<Typography
							sx={{
								fontWeight: 700,
								fontSize: "18px",
								color: hasAccordionErrors()
									? enerbitColors.error.main
									: "#667085",
							}}
						>
							Información personal
						</Typography>
					</div>
				</StyledAccordionSummary2>
				<StyledAccordionDetails2>
					<Grid container spacing={3}>
						{formPersonalInformation.map((item, i) => {
							const currentField = {
								...item,
								name: `${name}.${item.name}`,
							};
							return <FormItem {...currentField} key={i} />;
						})}
					</Grid>
				</StyledAccordionDetails2>
			</StyledAccordion2>
		</Grid>
	);
};
