import type {
	IAffiliationSocialSecurity,
	IAffiliationSocialSecurityItem,
	IConfinedAreas,
	IDriveLicense,
	IEmergencyContact,
	IFormDataOperator,
	IImageProfile,
	IPostUser,
} from "../interfaces";

export const defaultValuesUser: IPostUser = {
	username: "",
	password: "",
	rol_id: "",
	company_id: "",
	is_odoo_user: false,
	full_name: "",
	legal_id_type: "",
	legal_id_code: "",
	phone: "",
	email: "",
	form_data: null,
};

export const createDefaultCertification = (): IDriveLicense => ({
	path_certification: "",
	expiration_certification: "",
});

export const createDefaultAffiliationItem =
	(): IAffiliationSocialSecurityItem => ({
		path_certification: "",
		expiration_certification: "",
		entity: "",
	});

export const createDefaultAffiliation = (): IAffiliationSocialSecurity => ({
	affiliation_arl: null,
	affiliation_eps: null,
	affiliation_pension: null,
});

export const createDefaultDocument = (): IConfinedAreas => ({
	path_certification: "",
	date_issue: "",
});

export const createDeFaultImage = (): IImageProfile => ({
	path_photography: "",
});

export const createDefaultEmergencyContact = (): IEmergencyContact => ({
	full_name: "",
	phone: "",
});

export const defaultValuesFormData: IFormDataOperator = {
	social_security_payment_form: createDefaultAffiliation(),
	drive_license: null,
	safe_work_height: [createDefaultCertification()],
	introduction_road_safety: createDefaultDocument(),
	load_lifting: [createDefaultCertification()],
	confined_areas: null,
	electrical_hazard_training: [createDefaultCertification()],
	first_aid_training: createDefaultDocument(),
	professional_registration: {
		...createDefaultDocument(),
		registration_number: null,
		entity: null,
	},
	image_profile: null,
	blood_type: "",
	legal_id_document: createDefaultDocument(),
	health_record: null,
	emergency_contact: null,
	residence: null,
	coordinator: null,
	measurement_type_trained: [],
	company_vehicle_driving_authorization: null,
	voltage_works_permission: createDefaultCertification(),
};
