import { IDynamicForm } from "../interfaces";

export const formCertificationOperator: IDynamicForm[] = [
	{
		label: "Fecha de vencimiento",
		name: "expiration_certification",
		type: "date",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		props: {
			disablePast: true,
			disableToday: true,
		},
	},
	{
		label: "Documento",
		name: "path_certification",
		type: "file",
		sx: {
			xs: 12,
			md: 6,
		},
	},
];

export const formAffiliationOperator: IDynamicForm[] = [
	{
		label: "Fecha de expiración",
		name: "expiration_certification",
		type: "date",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		props: {
			disablePast: true,
			disableToday: true,
		},
	},
	{
		label: "Entidad",
		name: "entity",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Documento",
		name: "path_certification",
		type: "file",
		sx: {
			xs: 12,
			md: 6,
		},
	},
];
