import { IDynamicForm } from "../interfaces";

export const formDocumentationContract: IDynamicForm[] = [
	{
		label: "Contratos",
		type: "title",
	},
	{
		label: "Contrato",
		name: "form_data.path_contract",
		type: "array",
		children: [
			{
				label: "Contrato",
				type: "file",
			},
		],
		json: "",
	},
	{
		label: "Pólizas del contrato",
		type: "title",
	},
	{
		label: "Póliza",
		name: "form_data.policy",
		type: "array",
		children: [
			{
				label: "Fecha de expedición",
				name: "started_at",
				type: "date",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
				props: {
					disableFuture: true,
				},
			},
			{
				label: "Fecha de caducidad",
				name: "ended_at",
				type: "date",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
				props: {
					disablePast: true,
					disableToday: true,
				},
			},
			{
				label: "Valor",
				name: "value",
				type: "number",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
				props: {
					min: 0,
				},
			},
			{
				label: "Póliza",
				name: "url_path",
				type: "file",
			},
		],
		json: { url_path: "", value: 0, started_at: "", ended_at: "" },
	},
	{
		label: "Acta de inicio",
		type: "title",
	},
	{
		label: "Acta de inicio",
		name: "form_data.path_initial_record",
		type: "file",
	},
	{
		label: "Especificaciones técnicas",
		type: "title",
	},
	{
		label: "Especificación técnica",
		name: "form_data.path_technical_specifications",
		type: "array",
		children: [
			{
				label: "Especificación técnica",
				type: "file",
			},
		],
		json: "",
	},
	{
		label: "Documentos EPP",
		type: "title",
	},
	{
		label: "Documento EPP",
		name: "form_data.ppe_document",
		type: "array",
		children: [
			{
				label: "Documento EPP",
				type: "file",
			},
		],
		json: "",
	},
	{
		label: "Actas",
		type: "title",
	},
	{
		label: "Acta",
		name: "form_data.records",
		type: "array",
		children: [
			{
				label: "Nombre",
				name: "name",
				type: "text",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
			},
			{
				label: "Acta",
				name: "url_path",
				type: "file",
			},
		],
		json: {
			url_path: "",
			name: "",
		},
	},
	{
		label: "Facturas de cada mes",
		type: "title",
	},
	{
		label: "Factura",
		name: "form_data.invoice",
		type: "array",
		children: [
			{
				label: "Fecha de expedición",
				name: "started_at",
				type: "date",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
				props: {
					disableFuture: true,
				},
			},
			{
				label: "Fecha de caducidad",
				name: "ended_at",
				type: "date",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
				props: {
					disablePast: true,
					disableToday: true,
				},
			},
			{
				label: "Valor",
				name: "value",
				type: "number",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
				props: {
					min: 0,
				},
			},
			{
				label: "Factura",
				name: "url_path",
				type: "file",
			},
		],
		json: { url_path: "", value: 0, started_at: "", ended_at: "" },
	},
	{
		label: "Otros documentos",
		type: "title",
	},
	{
		label: "Acta",
		name: "form_data.other_documents",
		type: "array",
		children: [
			{
				label: "Nombre",
				name: "name",
				type: "text",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
			},
			{
				label: "Acta",
				name: "url_path",
				type: "file",
			},
		],
		json: {
			url_path: "",
			name: "",
		},
	},
	{
		label: "¿Hay algún contrato cancelado?",
		name: "form_data.cancelled_contract",
		type: "switch",
		children: [
			{
				label: "Observación",
				name: "observation",
				type: "text",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
			},
			{
				label: "Acta",
				name: "url_path",
				type: "file",
			},
		],
		json: {
			url_path: "",
			observation: "",
		},
	},
	{
		label: "Observaciones",
		type: "title",
	},
	{
		label: "Observación",
		name: "form_data.observations",
		type: "array",
		children: [
			{
				label: "Observación",
				type: "text",
				sx: {
					xs: 12,
					md: 6,
					lg: 3,
				},
			},
		],
		json: "",
	},
];
