import { Chip, styled } from "@enerbit/base";

export const StyledChip = styled(Chip)(() => ({
	borderRadius: "8px",
	"& .MuiChip-icon ": {
		color: "inherit",
		alignSelf: "center",
		paddingBottom: 5,
	},
	"& .MuiChip-label": {
		display: "flex",
		alignItems: "start",
	},
}));
