import {
	Grid,
	Stack,
	IconButton,
	ArrowCircleLeftOutlinedIcon,
	Typography,
	LoadingButton,
	useSnackbar,
	dayjs,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { defaultValuesContract } from "../form-defaults";
import {
	formCreateContract,
	formDocumentationContract,
} from "../form-builders";
import { FormItem } from "../components/dynamic-form";
import { PostContractSchema } from "../schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCompanyStore, useContractStore, useJWTStore } from "../store";
import { IPostContract } from "../interfaces";
import { convertDatesToISO } from "../helpers";

export const CreateContract = () => {
	const { decodedToken } = useJWTStore();
	const { company, loading, getCompany } = useCompanyStore((state) => state);
	const { createContract } = useContractStore((state) => state);

	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(false);

	const { id } = useParams();

	const methods = useForm<IPostContract>({
		defaultValues: defaultValuesContract,
		resolver: zodResolver(PostContractSchema),
		mode: "onChange",
		criteriaMode: "all",
	});

	const {
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = methods;

	const onSubmit = async (data: IPostContract) => {
		try {
			setIsLoading(true);
			const newData = convertDatesToISO(data);
			await createContract(newData);
			enqueueSnackbar("Contrato creado con éxito.", { variant: "success" });
			reset({ ...defaultValuesContract, company_id: id });
		} catch (error) {
			enqueueSnackbar((error as Error).message, { variant: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			getCompany(id);
		}
	}, [id, decodedToken]);

	useEffect(() => {
		if (company) {
			setValue("company_id", company.id);
		} else {
			setValue("company_id", "");
		}
	}, [company]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Stack spacing={3} direction="row" alignItems={"center"}>
							<Link to="/users-companies" style={{ textDecoration: "none" }}>
								<IconButton color="primary" size="small" sx={{ p: 0 }}>
									<ArrowCircleLeftOutlinedIcon fontSize="large" />
								</IconButton>
							</Link>
							<Typography
								color="primary"
								sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
							>
								Crear contrato
							</Typography>
						</Stack>
					</Grid>
					{formCreateContract.map((formField, index) => {
						return <FormItem {...formField} key={index} />;
					})}
					<Grid item xs={12}>
						<Typography
							color="primary"
							sx={{ fontSize: "28px", fontWeight: "bold" }}
						>
							Documentación
						</Typography>
					</Grid>
					{formDocumentationContract.map((formField, index) => {
						return <FormItem {...formField} key={index} />;
					})}
					<Grid item xs={12}>
						<LoadingButton
							loading={isLoading || loading}
							disabled={Object.keys(errors).length > 0}
							id="create-contract"
							type="submit"
							variant="contained"
							color="secondary"
						>
							Crear contrato
						</LoadingButton>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};
