import {
	ArrowCircleLeftOutlinedIcon,
	Grid,
	IconButton,
	LoadingButton,
	Stack,
	Typography,
	useSnackbar,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormItem } from "../components/dynamic-form";
import { RenderFormItems } from "../components/render";
import { DynamicLocationForm, PersonalInformation } from "../components/user";
import { DynamicVehicleForm } from "../components/user/DynamicVehicleForm";
import {
	createDocumentationVehicleAuthorization,
	formCertificationOperator,
	formCreateDocumentOperator,
	formCreateDocumentationOperator,
	formCreateUser,
} from "../form-builders";
import {
	createDefaultDocument,
	defaultValuesFormData,
	defaultValuesUser,
} from "../form-defaults";
import { cleanObject } from "../helpers/cleanObject";
import { IPostUser } from "../interfaces";
import { PostUserSchema } from "../schemas";
import { useJWTStore, useUserStore } from "../store";

export const CreateUser = () => {
	const { decodedToken } = useJWTStore();

	const [isLoading, setIsLoading] = useState(false);
	const [currentRol, setCurrentRol] = useState<string | null>(null);

	const isNotEB = decodedToken?.role === "contratista";

	const { enqueueSnackbar } = useSnackbar();

	const { createUser, roles } = useUserStore((state) => state);

	const methods = useForm<IPostUser>({
		defaultValues: defaultValuesUser,
		resolver: zodResolver(PostUserSchema),
		mode: "onChange",
	});

	const {
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors },
	} = methods;

	const onSubmit = async (data: IPostUser) => {
		try {
			setIsLoading(true);
			const form_data = data.form_data ? cleanObject(data.form_data) : null;
			console.log(data);
			await createUser({
				...data,
				username: data.email,
				form_data,
			});
			enqueueSnackbar("Usuario creado con éxito.", { variant: "success" });
			reset();
		} catch (error) {
			enqueueSnackbar((error as Error).message, { variant: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	const handleFormData = () => {
		const rolId = watch("rol_id");
		if (rolId && roles.length > 0) {
			const currentRoleCode =
				roles.find(({ value }) => value === rolId)?.code ?? "";

			setCurrentRol(currentRoleCode);

			if (
				currentRoleCode === "OPR" ||
				currentRoleCode === "operacion_energia_digital"
			) {
				setValue("form_data", defaultValuesFormData);
			} else {
				setValue("form_data", null);
			}
		} else {
			setValue("form_data", null);
		}
	};

	useEffect(() => {
		handleFormData();
	}, [watch("rol_id"), roles]);

	useEffect(() => {
		if (isNotEB) {
			setValue("company_id", decodedToken?.company_id ?? "");
		}
	}, [decodedToken, isNotEB]);

	useEffect(() => {
		if (roles.length > 0 && isNotEB) {
			const foundRole = roles.find(({ code }) => code && code === "OPR");

			const roleValue = foundRole?.value?.toString() ?? "";

			setValue("rol_id", roleValue);
		}
	}, [roles, isNotEB]);

	console.log(watch());

	useEffect(() => {
		if (watch("form_data")) {
			if (currentRol === "operacion_energia_digital") {
				setValue(
					"form_data.company_vehicle_driving_authorization",
					createDefaultDocument(),
				);
			} else {
				setValue("form_data.company_vehicle_driving_authorization", null);
			}
		}
	}, [currentRol, watch("form_data")]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Stack spacing={3} direction="row" alignItems={"center"}>
							<Link to="/users-companies" style={{ textDecoration: "none" }}>
								<IconButton color="primary" size="small" sx={{ p: 0 }}>
									<ArrowCircleLeftOutlinedIcon fontSize="large" />
								</IconButton>
							</Link>
							<Typography
								color="primary"
								sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
							>
								Crear usuario
							</Typography>
						</Stack>
					</Grid>
					<RenderFormItems items={formCreateUser.slice(0, 6)} />
					<DynamicLocationForm
						nameCountry={"country"}
						nameState={"state"}
						nameCity={"city"}
					/>

					{/* Parte condicional */}
					{isNotEB ? (
						<>
							<RenderFormItems
								items={formCreateUser.slice(6, 10).map((item) => {
									if (item.name === "rol_id") {
										return {
											...item,
											props: {
												disabled: true,
												...item.props,
											},
										};
									}
									return item;
								})}
							/>
							<RenderFormItems items={formCreateUser.slice(11)} />
						</>
					) : (
						<RenderFormItems items={formCreateUser.slice(6)} />
					)}
					{watch("form_data") !== null && (
						<>
							<FormItem
								name="form_data.measurement_type_trained"
								type={"checkbox-group"}
								label="Seleccione el tipo de medida que maneja este operador"
								items={[
									{
										text: "Directa",
										value: "directa",
									},
									{
										text: "Semidirecta",
										value: "semidirecta",
									},
									{
										text: "Indirecta",
										value: "indirecta",
									},
								]}
							/>
							<Grid item xs={12}>
								<Typography
									color="primary"
									sx={{ fontSize: "28px", fontWeight: "bold" }}
								>
									Documentación
								</Typography>
							</Grid>
							<FormItem
								name="form_data.coordinator.safe_work_height"
								type={"switch"}
								label="¿Es coordinador/a de trabajo en alturas?"
								children={[
									{
										label: "Certificado de trabajo en alturas ( coordinador )",
										type: "accordion",
										children: formCertificationOperator,
									},
								]}
								sx={{
									xs: 12,
								}}
								json={{ safe_work_height: null }}
							/>
							<FormItem
								name="form_data.confined_areas"
								type={"switch"}
								label="¿Está habilitada/o para trabajo en espacios confinados?"
								children={[
									{
										label: "Trabajo en espacios confinados",
										type: "accordion",
										children: formCreateDocumentOperator,
									},
								]}
								sx={{
									xs: 12,
								}}
								json={createDefaultDocument()}
							/>
							{currentRol === "OPR" && (
								<DynamicVehicleForm
									name={"form_data"}
									currentRol={currentRol ?? ""}
								/>
							)}
							{currentRol === "operacion_energia_digital" &&
								createDocumentationVehicleAuthorization.map(
									(formField, index) => {
										return <FormItem {...formField} key={index} />;
									},
								)}
							{formCreateDocumentationOperator
								.slice(0, 7)
								.map((formField, index) => {
									return <FormItem {...formField} key={index} />;
								})}
							<PersonalInformation name={"form_data"} />
							{currentRol === "operacion_energia_digital" && (
								<DynamicVehicleForm
									name={"form_data"}
									currentRol={currentRol ?? ""}
								/>
							)}
							{formCreateDocumentationOperator
								.slice(7)
								.map((formField, index) => {
									return <FormItem {...formField} key={index} />;
								})}
						</>
					)}
					<Grid item xs={12}>
						<LoadingButton
							loading={isLoading}
							id="create-user"
							type="submit"
							variant="contained"
							color="secondary"
						>
							Crear usuario
						</LoadingButton>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};
