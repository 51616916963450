import { FC } from "react";
import { IDynamicForm } from "../../interfaces";
import { ComponentMap } from ".";

export const FormItem: FC<IDynamicForm> = (field) => {
	const { type } = field;
	const Component = ComponentMap[type] as React.FC<IDynamicForm>;

	return <Component {...field} />;
};
