import { IDynamicForm } from "../interfaces";

export const formUpdateContract: IDynamicForm[] = [
	{
		label: "Código",
		name: "code",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Valor",
		name: "value",
		type: "number",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		props: {
			min: 0,
		},
	},
	{
		label: "Fecha inicial",
		name: "started_at",
		type: "date",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Fecha final",
		name: "ended_at",
		type: "date",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		props: {
			disableDatesBefore: "started_at",
		},
	},
	{
		label: "Estado del contrato",
		name: "status",
		type: "select",
		items: [
			{
				text: "Activo",
				value: "active",
			},
			{
				text: "Expirado",
				value: "expired",
			},
			{
				text: "Cancelado",
				value: "canceled",
			},
		],
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Descripción",
		name: "description",
		type: "text-area",
		sx: {
			xs: 12,
		},
	},
];
