import {
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputAdornment,
	SearchIcon,
	TextField,
	Typography,
	useSnackbar,
} from "@enerbit/base";
import { useCompanyStore } from "../../store";

export const SearchCompany = () => {
	const { mainSearch, setMainSearch, loading, filterCompany } = useCompanyStore(
		(state) => state,
	);

	const { enqueueSnackbar } = useSnackbar();

	const searchCompany = async () => {
		try {
			await filterCompany(mainSearch);
		} catch (error) {
			console.log(error);
			enqueueSnackbar("No se encontró la empresa.", {
				variant: "error",
			});
		}
	};

	return (
		<Grid container spacing={2} item>
			<Grid item xs={12}>
				<Typography>Consulta por NIT</Typography>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<Grid container spacing={3}>
						<Grid item flex={1}>
							<TextField
								fullWidth
								onKeyDown={(e) => e.key === "Enter" && searchCompany()}
								name={"search"}
								value={mainSearch}
								onChange={(e) => setMainSearch(e.target.value)}
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								type={"text"}
								id={"company-search"}
								disabled={loading}
								variant="outlined"
								placeholder="Buscar"
								InputProps={{
									endAdornment: loading && (
										<InputAdornment position="end">
											<CircularProgress size={20} thickness={6} />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item display="grid">
							<Button
								id="search-company"
								color="primary"
								variant="contained"
								onClick={searchCompany}
								disabled={loading || !mainSearch}
							>
								<SearchIcon />
							</Button>
						</Grid>
					</Grid>
				</FormControl>
			</Grid>
		</Grid>
	);
};
