/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { styled, IconButton, darken } from "@enerbit/base";

export const StyledIconButton = styled(IconButton)(({ theme, color }) => {
	let bgColor;
	let hoverColor;
	let borderColor;
	let textColor = "white";
	switch (color) {
		case "info":
			bgColor = "#007BFF"; // Sustituye por tu color azul deseado
			hoverColor = darken(bgColor, 0.1); // Oscurece el color en un 10%
			break;
		case "default":
			bgColor = "#6C757D"; // Sustituye por tu color gris deseado
			hoverColor = darken(bgColor, 0.1); // Oscurece el color en un 10%
			break;
		case "inherit":
			bgColor = "#FFF"; // Sustituye por tu color azul deseado
			borderColor = `1px solid ${theme.palette["error"].main}`;
			textColor = theme.palette["error"].main;
			hoverColor = darken(bgColor, 0.1); // Oscurece el color en un 10%
			break;
		default:
			bgColor = theme.palette[color!]?.main;
			hoverColor = theme.palette[color!]?.dark;
			break;
	}

	return {
		borderRadius: "10px",
		width: 40,
		height: 40,
		color: textColor,
		backgroundColor: bgColor,
		border: borderColor,
		"& svg": {
			color: textColor,
		},
		"&:hover": {
			border: borderColor,
			backgroundColor: hoverColor,
		},
	};
});
