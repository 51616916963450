import { HashRouter, Route, Routes } from "react-router-dom";
import {
	UsersCompanies,
	CreateUser,
	EditUser,
	CreateContract,
	EditContract,
	Contract,
} from "../pages";
import { useEffect } from "react";
import { useJWTStore } from "../store";
import { ProtectedRoute } from "./ProtectedRoute";

const Router = () => {
	const { loadAndDecodeJwt, decodedToken } = useJWTStore();

	useEffect(() => {
		if (!Array.isArray(decodedToken?.company_id)) {
			loadAndDecodeJwt();
		}
	}, [decodedToken?.company_id]);

	return (
		<HashRouter basename="/">
			<Routes>
				<Route path="/users-companies" element={<UsersCompanies />} />
				<Route path="/users-companies/create-user" element={<CreateUser />} />
				<Route path="/users-companies/edit-user/:id" element={<EditUser />} />
				<Route
					path="/users-companies/company/:id/create-contract"
					element={
						<ProtectedRoute
							element={<CreateContract />}
							condition={!(decodedToken?.role === "contratista")}
						/>
					}
				/>
				<Route
					path="/users-companies/edit-contract/:contract_id"
					element={
						<ProtectedRoute
							element={<EditContract />}
							condition={!(decodedToken?.role === "contratista")}
						/>
					}
				/>
				<Route
					path="/users-companies/contract/:contract_id"
					element={
						<ProtectedRoute
							element={<Contract />}
							condition={decodedToken?.role === "contratista"}
						/>
					}
				/>
			</Routes>
		</HashRouter>
	);
};

export default Router;
