import { api } from "@enerbit/base";
import { create } from "zustand";
import type { IRespUser, IUserItem, IUserState } from "../interfaces";
import { getDepartments, getRoles } from "../services";

export const useUserStore = create<IUserState>()((set) => ({
	search: "",
	debounceSearch: "",
	loading: false,
	pagination: {
		page: 0,
		size: 6,
		total: 1,
	},
	roles: [],
	listUsers: [],
	loadingUsers: false,
	departments: [],
	isActive: false,
	setSearch: (search) => set(() => ({ search: search })),
	setDebounceSearch: (debounce) => set(() => ({ debounceSearch: debounce })),
	setPage: (page) =>
		set((state) => ({
			pagination: {
				...state.pagination,
				page: page,
			},
		})),
	createUser: async (userData) => {
		try {
			await api.post("/ops-users/operators/", userData);
		} catch (error: any) {
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.detail?.detail?.includes("duplicate")
			) {
				throw new Error("Este usuario ya existe.");
			}
			throw new Error("Error al crear usuario.");
		}
	},
	setRoles: async () => {
		const roles = await getRoles();

		set({ roles });
		return roles;
	},
	getUsers: async (
		page,
		is_active,
		rol,
		email,
		is_enable,
		state,
		city,
		measurement_type_trained,
		company_id,
		is_coordinator,
		is_safe_work_height_coordinator,
		is_safe_work_height,
	) => {
		try {
			set({ loadingUsers: true });

			const { data } = await api.get<IRespUser>(
				`/ops-users/operators/?page=${page}&size=6${
					is_active !== undefined ? `&is_active=${is_active}` : ""
				}${rol ? `&rol_code=${rol}` : ""}${email ? `&username=${email}` : ""}${
					is_enable !== undefined ? `&pii_status=${is_enable}` : ""
				}${state ? `&state=${state}` : ""}${city ? `&city=${city}` : ""}${
					measurement_type_trained
						? `&measurement_type_trained=${measurement_type_trained}`
						: ""
				}${company_id ? `&company_id=${company_id}` : ""}${
					is_coordinator !== undefined ? `&coordinator=${is_coordinator}` : ""
				}${
					is_safe_work_height_coordinator !== undefined
						? `&coordinator_enabled_work_height=${is_safe_work_height_coordinator}`
						: ""
				}${
					is_safe_work_height !== undefined
						? `&enabled_work_height=${is_safe_work_height}`
						: ""
				}`,
			);
			set({
				listUsers: data.items,
				loadingUsers: false,
				pagination: {
					page,
					size: 6,
					total: data.total,
				},
			});
		} catch (error) {
			set({
				listUsers: [],
				loadingUsers: false,
				pagination: {
					page: 0,
					size: 6,
					total: 1,
				},
			});
		}
	},
	getUser: async (id) => {
		try {
			const { data } = await api.get<IUserItem>(`/ops-users/operators/${id}/`);
			return data;
		} catch (error) {
			throw new Error("Error al buscar usuario.");
		}
	},
	setDepartments: async () => {
		const departments = await getDepartments();
		set({ departments });
		return departments;
	},
	updateUser: async (id, userData) => {
		try {
			await api.patch(`/ops-users/operators/${id}`, userData);
		} catch (error) {
			console.log(error);
			throw new Error("Error al editar usuario.");
		}
	},
	setIsActive: (isActive) => set({ isActive: isActive }),
	disableUser: async (id) => {
		try {
			await api.patch(`/ops-users/operators/${id}`, { is_active: false });
		} catch (error) {
			throw new Error("Error al cambiar el estado del usuario.");
		}
	},
}));
