import { z } from "zod";

export const PostContractSchema = z.object({
	description: z.string().min(1, "Este campo es obligatorio."),
	code: z.string().min(1, "Este campo es obligatorio."),
	value: z.number().positive("Este campo debe tener un valor valido."),
	started_at: z
		.string()
		.nullable()
		.refine((val) => val !== null && val.length > 0, {
			message: "Este campo es obligatorio.",
		})
		.refine(
			(val) => {
				if (val === null || val === "") return true;
				const parsedDate = new Date(val);
				return !isNaN(parsedDate.getTime());
			},
			{
				message: "Fecha inválida.",
			},
		),
	ended_at: z
		.string()
		.nullable()
		.refine((val) => val !== null && val.length > 0, {
			message: "Este campo es obligatorio.",
		})
		.refine(
			(val) => {
				if (val === null || val === "") return true;
				const parsedDate = new Date(val);
				return !isNaN(parsedDate.getTime());
			},
			{
				message: "Fecha inválida.",
			},
		),
	company_id: z.string().min(1, "Este campo es obligatorio."),
	form_data: z.object({
		path_contract: z
			.array(z.string().min(1, "Este campo es obligatorio."))
			.min(1, "Debe tener al menos un contrato."),
		path_initial_record: z.string().nullable(),
		records: z
			.array(
				z.object({
					url_path: z.string().min(1, "Este campo es obligatorio."),
					name: z.string().min(1, "Este campo es obligatorio."),
				}),
			)
			.nullable(),
		path_technical_specifications: z
			.array(z.string().min(1, "Este campo es obligatorio."))
			.nullable(),
		ppe_document: z
			.array(z.string().min(1, "Este campo es obligatorio."))
			.nullable(),
		invoice: z
			.array(
				z.object({
					url_path: z.string().min(1, "Este campo es obligatorio."),
					value: z.number().positive("Este campo debe tener un valor valido."),
					started_at: z.string().min(1, "Este campo es obligatorio."),
					ended_at: z.string().min(1, "Este campo es obligatorio."),
				}),
			)
			.nullable(),
		policy: z
			.array(
				z.object({
					url_path: z.string().min(1, "Este campo es obligatorio."),
					value: z.number().positive("Este campo debe tener un valor valido."),
					started_at: z.string().min(1, "Este campo es obligatorio."),
					ended_at: z.string().min(1, "Este campo es obligatorio."),
				}),
			)
			.nullable(),
		observations: z
			.array(z.string().min(1, "Este campo es obligatorio."))
			.nullable(),
		cancelled_contract: z
			.object({
				observation: z.string().min(1, "Este campo es obligatorio."),
				url_path: z.string().min(1, "Este campo es obligatorio."),
			})
			.nullable(),
		other_documents: z
			.array(
				z.object({
					url_path: z.string().min(1, "Este campo es obligatorio."),
					name: z.string().min(1, "Este campo es obligatorio."),
				}),
			)
			.nullable(),
	}),
});
