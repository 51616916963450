import { NavSidebar, Box, Container } from "@enerbit/base";
import { FC, ReactNode } from "react";

interface Props {
	children: ReactNode;
}
const MainLayout: FC<Props> = ({ children }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				height: "100vh",
				width: "100vw",
				overflow: "hidden",
			}}
		>
			<NavSidebar />
			<Box
				sx={{
					position: "relative",
					flexGrow: 1,
					paddingTop: "70px",
					overflow: "auto",
				}}
			>
				<Container sx={{ py: 4 }} maxWidth={"xl"}>
					<div>{children}</div>
				</Container>
			</Box>
		</div>
	);
};

export default MainLayout;
