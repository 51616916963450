import { IDynamicForm } from "../interfaces";
import { getCompanies } from "../services";
import { useUserStore } from "../store";
import {
	formAffiliationOperator,
	formCertificationOperator,
	formCreateCertificationOperator,
} from ".";
import { createDefaultAffiliationItem } from "../form-defaults";
import { isDateBeforeExpirationMonths } from "../schemas";

export const formUpdateUser: IDynamicForm[] = [
	// Datos personales
	{
		label: "Datos personales",
		type: "title",
	},
	{
		label: "Nombre completo",
		name: "pii.full_name",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Tipo de documento",
		name: "pii.legal_id_type",
		type: "select",
		items: [
			{
				text: "CC",
				value: "CC",
			},
			{
				text: "NIT",
				value: "NIT",
			},
		],
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Número de documento",
		name: "pii.legal_id_code",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},

	// Información de contacto
	{
		label: "Información de contacto",
		type: "title",
	},
	{
		label: "Teléfono",
		name: "pii.phone",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},

	// Información laboral
	{
		label: "Información laboral",
		type: "title",
	},
	{
		label: "Correo electrónico",
		name: "pii.email",
		type: "email",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Rol",
		name: "rol_id",
		type: "select",
		fetchItems: () => useUserStore.getState().setRoles(),
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
	{
		label: "Empresa",
		name: "company_id",
		type: "select",
		fetchItems: () => getCompanies(),
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
	},
];

export const formUpdateDocumentOperator: IDynamicForm[] = [
	{
		label: "Fecha de expedición",
		name: "date_issue",
		type: "date",
		sx: {
			xs: 12,
			md: 6,
			lg: 3,
		},
		props: {
			disableFuture: true,
		},
	},
	{
		label: "Documento",
		name: "path_certification",
		type: "file",
	},
];

export const formUpdateDocumentationOperator: IDynamicForm[] = [
	{
		label: "Capacitación en levantamiento de cargas",
		name: "pii.form_data.load_lifting.0",
		type: "accordion",
		children: formCertificationOperator,
		props: {
			expiration: {
				expirationField:
					"pii.form_data.load_lifting.0.expiration_certification",
				notifyMonthsBefore: 1,
			},
		},
	},
	{
		label: "Capacitación en seguridad vial y/o conducción defensiva",
		name: "pii.form_data.introduction_road_safety",
		type: "accordion",
		children: formUpdateDocumentOperator,
	},
	{
		label: "Capacitación riesgo eléctrico",
		name: "pii.form_data.electrical_hazard_training.0",
		type: "accordion",
		children: formCertificationOperator,
		props: {
			expiration: {
				expirationField:
					"pii.form_data.electrical_hazard_training.0.expiration_certification",

				notifyMonthsBefore: 2,
			},
		},
	},
	{
		label: "Certificado de trabajo en alturas",
		name: "pii.form_data.safe_work_height.0",
		type: "accordion",
		children: formCertificationOperator,
		props: {
			expiration: {
				expirationField:
					"pii.form_data.safe_work_height.0.expiration_certification",

				notifyMonthsBefore: 1,
			},
		},
	},
	{
		label: "Formación en primeros auxilios",
		name: "pii.form_data.first_aid_training",
		type: "accordion",
		children: formUpdateDocumentOperator,
	},
	{
		label: "Formulario de pago a la seguridad social",
		name: "pii.form_data.social_security_payment_form",
		type: "accordion",
		children: [
			{
				label: "ARL",
				name: "affiliation_arl.0",
				type: "switch",
				sx: {
					xs: 12,
				},
				children: formAffiliationOperator,
				json: createDefaultAffiliationItem(),
				props: {
					expiration: {
						expirationField:
							"pii.form_data.social_security_payment_form.affiliation_arl.0.expiration_certification",
						notifyDaysBefore: 5,
					},
				},
			},
			{
				label: "EPS",
				name: "affiliation_eps.0",
				type: "switch",
				sx: {
					xs: 12,
				},
				children: formAffiliationOperator,
				json: createDefaultAffiliationItem(),
				props: {
					expiration: {
						expirationField:
							"pii.form_data.social_security_payment_form.affiliation_eps.0.expiration_certification",
						notifyDaysBefore: 5,
					},
				},
			},
			{
				label: "Pensión",
				name: "affiliation_pension.0",
				type: "switch",
				sx: {
					xs: 12,
				},
				children: formAffiliationOperator,
				json: createDefaultAffiliationItem(),
				props: {
					expiration: {
						expirationField:
							"pii.form_data.social_security_payment_form.affiliation_pension.0.expiration_certification",
						notifyDaysBefore: 5,
					},
				},
			},
		],
	},
	{
		label: "Habilitación para trabajos con tensión",
		name: "pii.form_data.voltage_works_permission",
		type: "accordion",
		children: formCreateCertificationOperator,
		props: {
			expiration: {
				expirationField:
					"pii.form_data.voltage_works_permission.expiration_certification",

				notifyMonthsBefore: 2,
			},
		},
	},
	{
		label: "Matricula profesional",
		name: "pii.form_data.professional_registration",
		type: "accordion",
		children: [
			...formUpdateDocumentOperator,
			{
				label: "Número de registro",
				type: "text",
				name: "registration_number",
				sx: {
					xs: 12,
					md: 6,
				},
			},
			{
				label: "Entidad",
				type: "text",
				name: "entity",
				sx: {
					xs: 12,
					md: 6,
				},
			},
		],
	},
];

export const updateDocumentationVehicleAuthorization: IDynamicForm[] = [
	{
		label: "Autorización para conducir vehículos de la empresa",
		name: "pii.form_data.company_vehicle_driving_authorization",
		type: "accordion",
		children: formUpdateDocumentOperator,
	},
];
