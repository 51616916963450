import { AddIcon, Box, Button, Grid, Typography } from "@enerbit/base";
import { useCompanyStore, useContractStore, useJWTStore } from "../../store";
import { DynamicSearchCompany } from "../search";
import { FileCompanyList } from "./FileCompanyList";
import { PaginationCompany } from "./PaginationCompany";
import { Link } from "react-router-dom";

export const HeaderContract = () => {
	const loading = useContractStore((state) => state.loading);
	const company = useCompanyStore((state) => state.company);
	const { decodedToken } = useJWTStore();

	return (
		<Grid item xs={12}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography
						color="primary"
						sx={{ fontSize: "21px", fontWeight: 700 }}
					>
						Contratos
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Box display="flex" sx={{ justifyContent: "space-between", gap: 4 }}>
						<DynamicSearchCompany />
						{!(decodedToken?.role === "contratista") && (
							<Link
								to={`/users-companies/company/${company?.id}/create-contract`}
								style={{ textDecoration: "none", flexShrink: 0, flexGrow: 0 }}
							>
								<Button
									disabled={loading}
									color="primary"
									variant="outlined"
									fullWidth
									sx={{ height: "100%", minWidth: "150px" }} // Asegúrate de que el botón tenga un ancho mínimo
									startIcon={<AddIcon />}
								>
									Agregar contrato
								</Button>
							</Link>
						)}
					</Box>
				</Grid>
				<FileCompanyList />
				<PaginationCompany />
			</Grid>
		</Grid>
	);
};
