import { api, geopoliticsApi } from "@enerbit/base";
import { capitalizeFirstLetter } from ".";
import type {
	IItemsJson,
	IRespCity,
	IRespCompany,
	IRespCountry,
	IRespRol,
	IRespState,
} from "../interfaces";

export const getRoles: () => Promise<IItemsJson[]> = async () => {
	try {
		const { data } = await api.get<IRespRol>("/ops-users/roles/");

		const options = data.items.map(({ name, id, code }) => ({
			code,
			text: capitalizeFirstLetter(name),
			value: id,
		}));

		// Ordenando por 'text' de manera alfabética
		options.sort((a, b) => a.text.localeCompare(b.text));

		return options;
	} catch (error) {
		return [];
	}
};

export const getRolesCode: () => Promise<IItemsJson[]> = async () => {
	try {
		const { data } = await api.get<IRespRol>("/ops-users/roles/");

		const options = data.items.map(({ name, code }) => ({
			text: capitalizeFirstLetter(name),
			value: code,
		}));

		// Ordenando por 'text' de manera alfabética
		options.sort((a, b) => a.text.localeCompare(b.text));

		return options;
	} catch (error) {
		return [];
	}
};

export const getCompanies: () => Promise<IItemsJson[]> = async () => {
	try {
		let allCompanies: { text: string; value: string }[] = [];
		let page = 0;
		let hasMore = true;

		while (hasMore) {
			const { data } = await api.get(
				`/ops-users/companies/?page=${page}&size=50`,
			);

			const formatCompanies = data.items.map(
				({ name, id }: { name: string; id: string }) => ({
					text: capitalizeFirstLetter(name),
					value: id,
				}),
			);

			allCompanies = [...allCompanies, ...formatCompanies];

			if (!data.next_page) {
				hasMore = false;
			} else {
				page++;
			}
		}

		return allCompanies.sort((a, b) => a.text.localeCompare(b.text));
	} catch (err) {
		return [];
	}
};

export const getCountries: () => Promise<IItemsJson[]> = async () => {
	const { data } = await geopoliticsApi.get<IRespCountry>("/countries/");

	const options: IItemsJson[] = data.items
		.filter(({ iso2 }) => iso2 === "CO")
		.map(({ name, iso2 }) => ({
			text: name,
			value: iso2.toLowerCase(),
		}));

	return options;
};

export const getDepartments: () => Promise<IItemsJson[]> = async () => {
	try {
		const { data } = await geopoliticsApi.get<IRespState>(
			"/states/?country_code=CO",
		);

		const options: IItemsJson[] = data.items.map(({ name, state_code }) => ({
			text: name,
			value: state_code,
		}));

		return options;
	} catch (error) {
		return [];
	}
};

export const getCities: (department?: string) => Promise<IItemsJson[]> = async (
	department,
) => {
	try {
		if (!department) return [];

		const { data } = await geopoliticsApi.get<IRespCity>(
			`/cities/?state_code=${department}&country_code=CO`,
		);

		return data.items.map(({ name }) => ({
			text: name,
			value: name,
		}));
	} catch (error) {
		console.error(error);
		return [];
	}
};
