import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

type ProtectedRouteProps = {
	element: ReactElement;
	condition: boolean;
};

export const ProtectedRoute = ({ element, condition }: ProtectedRouteProps) => {
	return condition ? element : <Navigate to="/users-companies" />;
};
