import { create } from "zustand";
import { IContractState } from "../interfaces/IContractState";
import { IContractItem, IRespContract } from "../interfaces";
import { api } from "@enerbit/base";

export const useContractStore = create<IContractState>()((set) => ({
	search: "",
	pagination: {
		page: 0,
		size: 6,
		total: 1,
	},
	loading: false,
	listContract: [],
	setSearch: (filter) => set({ search: filter }),
	getContracts: async (id, filter, page) => {
		try {
			set({ loading: true });
			const { data } = await api.get<IRespContract>(
				`/ops-users/contract/?company_id=${id}&contract_code=${filter}&page=${page}&size=6`,
			);

			set((state) => ({
				listContract: data.items,
				pagination: {
					...state.pagination,
					total: data.total,
				},
				loading: false,
			}));
		} catch (error) {
			set({ loading: false });
			throw new Error("Error al encontrar los contratos.");
		}
	},
	setPage: (page) =>
		set((state) => ({
			pagination: {
				...state.pagination,
				page: page,
			},
		})),
	createContract: async (contract) => {
		try {
			await api.post("/ops-users/contract/", contract);
		} catch (error) {
			throw new Error("Error al crear contrato.");
		}
	},
	getContract: async (id) => {
		try {
			set({ loading: true });
			const { data } = await api.get<IContractItem>(
				`/ops-users/contract/${id}`,
			);

			set(() => ({
				loading: false,
			}));
			return data;
		} catch (error) {
			set({ loading: false });
			throw new Error("Error al encontrar el contrato.");
		}
	},
	updateContract: async (id, contract) => {
		try {
			await api.patch(`/ops-users/contract/${id}`, contract);
		} catch (error) {
			throw new Error("Error al editar contrato.");
		}
	},
}));
