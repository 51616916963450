import {
	Typography,
	Grid,
	Avatar,
	enerbitColors,
	ExpandCircleDownOutlinedIcon,
	AddIcon,
	CloseIcon,
} from "@enerbit/base";
import { FormItem } from "../FormItem";
import {
	StyledAccordion2,
	StyledAccordionSummary2,
	StyledAccordionDetails2,
} from "../../../common";
import { useState, SyntheticEvent, FC } from "react";
import { IDynamicForm } from "../../../interfaces";
import { get, useFormContext } from "react-hook-form";
import { useExpirationNotification } from "../../../hooks";

export const AccordionForm: FC<IDynamicForm> = ({
	name,
	children,
	label,
	props,
}) => {
	const {
		formState: { errors },
		watch,
	} = useFormContext();

	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChange =
		(panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	const expirationField = props?.expiration?.expirationField
		? watch(props.expiration?.expirationField)
		: null;

	const { showNotificationExpiration, expirationMessage } =
		useExpirationNotification({
			expirationField,
			notifyMonthsBefore: props?.expiration?.notifyMonthsBefore,
			notifyDaysBefore: props?.expiration?.notifyDaysBefore,
		});

	return (
		<Grid item xs={12}>
			<StyledAccordion2
				expanded={expanded === name}
				onChange={handleChange(name!)}
				sx={{
					borderColor: !!get(errors, name)
						? enerbitColors.error.main
						: undefined,
				}}
				id={name!}
			>
				<StyledAccordionSummary2
					expandIcon={
						<ExpandCircleDownOutlinedIcon
							sx={{
								fontSize: "1.5rem",
								color: !!get(errors, name)
									? enerbitColors.error.main
									: undefined,
							}}
						/>
					}
				>
					<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
						<Avatar
							color="primary"
							sx={{
								borderRadius: "50%",
								bgcolor: !!get(errors, name)
									? enerbitColors.error.main
									: enerbitColors.primary.main,
								width: 24,
								height: 24,
							}}
						>
							{!!get(errors, name) ? <CloseIcon /> : <AddIcon />}
						</Avatar>
						<Typography
							sx={{
								fontWeight: 700,
								fontSize: "18px",
								color: !!get(errors, name)
									? enerbitColors.error.main
									: "#667085",
							}}
						>
							{label}
						</Typography>
						{showNotificationExpiration && (
							<Typography
								sx={{
									color: enerbitColors.warning.main,
									fontWeight: 500,
									fontSize: "14px",
									marginLeft: "8px",
								}}
							>
								{expirationMessage}
							</Typography>
						)}
					</div>
				</StyledAccordionSummary2>
				<StyledAccordionDetails2>
					<Grid container spacing={3}>
						{children &&
							children?.map((item, i) => {
								const currentField = {
									...item,
									name: `${name}.${item.name}`,
								};

								return <FormItem {...currentField} key={i} />;
							})}
					</Grid>
				</StyledAccordionDetails2>
			</StyledAccordion2>
		</Grid>
	);
};
