// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf {
    width: 45px;
    height: 50px;
    background: #f04438;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }`, "",{"version":3,"sources":["webpack://./src/pages/UsersCompanies.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB","sourcesContent":[".pdf {\n    width: 45px;\n    height: 50px;\n    background: #f04438;\n    border-radius: 4px;\n    font-weight: 700;\n    font-size: 12px;\n    line-height: 14px;\n    text-align: center;\n    color: #ffffff;\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
