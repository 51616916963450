import {
	Grid,
	Stack,
	IconButton,
	ArrowCircleLeftOutlinedIcon,
	Typography,
	LoadingButton,
	useSnackbar,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import {
	formDocumentationContract,
	formUpdateContract,
} from "../form-builders";
import { FormItem } from "../components/dynamic-form";
import { PatchContractSchema } from "../schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCompanyStore, useContractStore } from "../store";
import { IPatchContract } from "../interfaces";
import { EditContractSkeleton } from "../components/skeleton";
import { convertDatesToISO } from "../helpers";

export const EditContract = () => {
	const [isContractLoading, setIsContractLoading] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { setCompany } = useCompanyStore();
	const { getContract, updateContract } = useContractStore((state) => state);

	const [isLoading, setIsLoading] = useState(false);

	const { contract_id } = useParams();

	const methods = useForm<IPatchContract>({
		resolver: zodResolver(PatchContractSchema),
		mode: "onChange",
	});

	const { handleSubmit, reset } = methods;

	const onSubmit = async (data: IPatchContract) => {
		try {
			if (contract_id) {
				setIsLoading(true);
				const newData = convertDatesToISO(data);
				await updateContract(contract_id, newData);
				enqueueSnackbar("Contrato editado con éxito.", { variant: "success" });
			}
		} catch (error) {
			enqueueSnackbar((error as Error).message, { variant: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	const setContract = async () => {
		if (contract_id) {
			try {
				setIsContractLoading(true);
				const contractData = await getContract(contract_id);
				setCompany(contractData.company);
				reset({ ...contractData, company_id: contractData.company.id });
				setIsContractLoading(false);
			} catch (error) {
				setIsContractLoading(false);
				enqueueSnackbar((error as Error).message, { variant: "error" });
			}
		}
	};

	useEffect(() => {
		setContract();
	}, [contract_id]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Stack spacing={3} direction="row" alignItems={"center"}>
							<Link to="/users-companies" style={{ textDecoration: "none" }}>
								<IconButton color="primary" size="small" sx={{ p: 0 }}>
									<ArrowCircleLeftOutlinedIcon fontSize="large" />
								</IconButton>
							</Link>
							<Typography
								color="primary"
								sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
							>
								Editar contrato
							</Typography>
						</Stack>
					</Grid>
					{!isContractLoading ? (
						<>
							{formUpdateContract.map((formField, index) => {
								return <FormItem {...formField} key={index} />;
							})}
							<Grid item xs={12}>
								<Typography
									color="primary"
									sx={{ fontSize: "28px", fontWeight: "bold" }}
								>
									Documentación
								</Typography>
							</Grid>
							{formDocumentationContract.map((formField, index) => {
								return <FormItem {...formField} key={index} />;
							})}
							<Grid item xs={12}>
								<LoadingButton
									loading={isLoading}
									id="create-user"
									type="submit"
									variant="contained"
									color="secondary"
								>
									Editar contrato
								</LoadingButton>
							</Grid>
						</>
					) : (
						<EditContractSkeleton />
					)}
				</Grid>
			</form>
		</FormProvider>
	);
};
