import { IDynamicForm } from "../interfaces";

export const formCompany: IDynamicForm[] = [
	{
		label: "Nombre",
		name: "name",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
		},
	},
	{
		label: "Dirección",
		name: "address",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
		},
	},
	{
		label: "Nit",
		name: "legal_id_code",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
		},
	},
	{
		label: "Teléfono",
		name: "phone",
		type: "text",
		sx: {
			xs: 12,
			md: 6,
		},
	},
	{
		label: "Correo electrónico",
		name: "email",
		type: "email",
		sx: {
			xs: 12,
			md: 6,
		},
	},
];
