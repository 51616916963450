import { Grid, Skeleton } from "@enerbit/base";

export const HeaderContractSkeleton = () => {
	return (
		<Grid container item spacing={3}>
			<Grid item xs={12}>
				{/* Skeleton para el título "Contratos" */}
				<Skeleton variant="text" width={150} height={60} />
			</Grid>
			<Grid item xs={10}>
				{/* Skeleton para el componente de búsqueda */}
				<Skeleton variant="rounded" width="100%" height={50} />
			</Grid>
			<Grid item xs={2}>
				{/* Skeleton para el botón "Agregar contrato" */}
				<Skeleton variant="rounded" width="100%" height={50} />
			</Grid>
		</Grid>
	);
};
