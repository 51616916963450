import { Grid, Skeleton } from "@enerbit/base";
import { FC } from "react";

// Define types for the skeleton sizes
type SkeletonSize = {
	width?: number | string;
	height: number;
};

const SKELETON_SIZES: { [key: string]: SkeletonSize } = {
	text: { width: 110, height: 40 },
	switch: { width: 56, height: 28 },
	input: { height: 56 },
	button: { width: 150, height: 46 },
};

// Skeleton components with predefined sizes
const SkeletonText: FC<SkeletonSize> = ({ width, height }) => (
	<Skeleton variant="text" width={width} height={height} />
);

const SkeletonRounded: FC<SkeletonSize> = ({ width, height }) => (
	<Skeleton variant="rounded" width={width} height={height} />
);

// Props for the input skeletons function
type RenderInputSkeletonsProps = {
	count: number;
	size: SkeletonSize;
};

const renderInputSkeletons = ({ count, size }: RenderInputSkeletonsProps) =>
	Array.from({ length: count }, (_, index) => (
		<Grid item xs={12} md={6} lg={3} key={index}>
			<SkeletonRounded width="100%" height={size.height} />
		</Grid>
	));

export const EditContractSkeleton = () => {
	return (
		<Grid container spacing={3} padding={3}>
			{/* Contract Info Section */}
			{renderInputSkeletons({
				count: 4,
				size: SKELETON_SIZES.input,
			})}

			<Grid item xs={12}>
				<SkeletonText {...SKELETON_SIZES.text} />
			</Grid>
			<Grid item xs={12}>
				<SkeletonRounded {...SKELETON_SIZES.switch} />
			</Grid>

			<Grid item xs={12}>
				<Skeleton variant="text" width="100%" height={140} />
			</Grid>

			{/* Documentation Section */}
			<Grid item xs={12}>
				<Skeleton variant="text" width={150} height={70} />
			</Grid>
			<Grid item xs={12}>
				<SkeletonText {...SKELETON_SIZES.text} />
			</Grid>
			<Grid item xs={12}>
				<Skeleton variant="text" width={"100%"} height={70} />
			</Grid>
			<Grid item xs={12}>
				<Skeleton variant="rectangular" width="100%" height={150} />
			</Grid>

			{/* Add Button */}
			<Grid item xs={12}>
				<SkeletonRounded {...SKELETON_SIZES.button} />
			</Grid>
		</Grid>
	);
};
