import { z } from "zod";
import { FormDataOperatorSchema } from ".";

export const PatchUserSchema = z.object({
	rol_id: z.string().min(1, "Este campo es obligatorio."),
	company_id: z.string().min(1, "Este campo es obligatorio."),
	is_active: z.boolean(),
	pii: z
		.object({
			full_name: z.string().min(1, "Este campo es obligatorio."),
			legal_id_type: z.string().min(1, "Este campo es obligatorio."),
			legal_id_code: z.string().min(1, "Este campo es obligatorio."),
			phone: z.string().min(1, "Este campo es obligatorio."),
			email: z
				.string()
				.min(1, "Este campo es obligatorio.")
				.email("Correo electrónico invalido."),
			country: z.string().min(1, "Este campo es obligatorio."),
			state: z.string().min(1, "Este campo es obligatorio."),
			city: z.string().min(1, "Este campo es obligatorio."),
			form_data: FormDataOperatorSchema.nullable(),
		})
		.nullable(),
});
